<template>
    <v-container 
        fluid 
    >
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Cotação</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>

            <v-row>

                <v-col
                    cols="12"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="12"
                                    >
                                        Detalhes da Cotação
                                    </v-col>
                                    <v-col
                                        style="text-align: right"
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="12"
                                    >
                                        <v-chip
                                            :color="getStatusColor(orcamentoRequest.status)"
                                            outlined
                                            dark
                                        >
                                            {{ orcamentoRequest.statusDescricao }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="6"
                                    >
                                        <label>ID</label>
                                        <v-text-field
                                            v-model="orcamentoRequest.idFormatted" 
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="6"
                                    >
                                        <label>Marca</label>
                                        <v-text-field
                                            v-model="orcamentoRequest.marcaDescricao" 
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="2"
                                        md="2"
                                        sm="6"
                                    >
                                        <label>Criada Em</label>
                                        <v-text-field
                                            v-model="orcamentoRequest.dataCadastroFormatted" 
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            disabled
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        class="justify-center"
                                        style="display: flex; align-content: center; align-items: center;"
                                        cols="12"
                                        lg="2"
                                        md="2"
                                        sm="6"
                                    >
                                        <v-switch 
                                            v-model="entregaTurbo"
                                            label="Entrega turbo"
                                            color="var(--color__main)"
                                            :value=1
                                            @change="aplicarEntregaTurbo()"
                                        >
                                        </v-switch>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="6"
                                    >
                                        <label>Condição de Pagamento</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.condicaoPagamentoSelected"
                                            :items="listCondicaoPagamento"
                                            :rules=[validations.required]
                                            item-text="descricao"
                                            item-value="id"
                                            outlined
                                            :disabled="abaterSaldoCampanhaCredito == true && isTipoDistribuidor == true"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="6"
                                    >
                                        <label>Forma de Pagamento</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.formaPagamentoSelected"
                                            :items="listFormaPagamento"
                                            :rules=[validations.required]
                                            item-text="descricao"
                                            item-value="id"
                                            outlined
                                            :disabled="abaterSaldoCampanhaCredito == true && isTipoDistribuidor == true"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="6"
                                    >
                                        <label>Tipo de Frete</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.tipoFreteSelected"
                                            :items="listTipoFrete"
                                            :rules=[validations.required]
                                            item-text="descricao"
                                            item-value="id"
                                            outlined
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        v-if="isUsuarioInterno"
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Tipo Cliente</label>
                                        <v-combobox
                                            v-model="tipoClienteSelected"
                                            :items="listTipoCliente"
                                            :rules=[validations.required]
                                            item-text="descricao"
                                            item-value="id"
                                            outlined
                                            @change="carregarDescontos(); verificaKeyAccount();"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        v-if="(isUsuarioInterno && isTipoDistribuidor) || temDistribuidorPai"
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Distribuidor</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.clienteSelected"
                                            :items="listDistribuidor"
                                            item-text="nameAndCnpj"
                                            item-value="id"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            :loading="loadingDistribuidor"
                                            @change="consultarCampanhas()"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        v-if="isUsuarioInterno && checkTipoCliente"
                                        cols="12"
                                        lg="4"
                                        md="6"
                                        sm="12"
                                    >
                                        <label>Cliente</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.clienteSelected"
                                            :items="listClientesFiltered"
                                            item-text="nameAndCnpj"
                                            item-value="id"
                                            :rules=[validations.required]
                                            single-line
                                            outlined
                                            :loading="loadingClientes"
                                            @change="getListProdutosKeyAccount()"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        v-if="isUsuarioInterno && (isTipoDistribuidor || checkTipoCliente)"
                                        cols="12"
                                        lg="2"
                                        md="4"
                                        sm="12"
                                    >
                                        <label>Tipo de Pedido</label>
                                        <v-combobox
                                            v-model="orcamentoRequest.tipoPedidoSelected"
                                            :items="listTipoPedido"
                                            :rules=[validations.required]
                                            item-text="descricao"
                                            item-value="id"
                                            outlined
                                            @change="impostoCalculado = false;"
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>

            </v-row>

            <v-row>

                <v-col
                    cols="12"
                >
                    <v-card-text>

                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        Itens
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="6"
                                    >
                                        <label>Código do Produto</label>
                                        <v-text-field
                                            v-model="codigoProduto"
                                            append-outer-icon="mdi mdi-magnify"
                                            hint="Pesquise diretamente pelo código do produto ou utilize os filtros abaixo"
                                            :persistent-hint=true
                                            single-line
                                            outlined
                                            @keyup.enter="getListProduto()"
                                            @click:append-outer="getListProduto()"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="6"
                                        md="6"
                                        sm="6"
                                        style="text-align: right;"
                                    >
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="novaBusca()"
                                                >
                                                    Nova Busca
                                                </v-btn>
                                            </template>
                                            <span>Nova Busca</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="6"
                                    >
                                        <label>Grandeza</label>
                                        <v-combobox
                                            v-model="grandezaSelected"
                                            :items="listGrandeza"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            @change="clearFields(); getListParametros(); getListTipoProduto();"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="6"
                                    >
                                        <label>Marca</label>
                                        <v-combobox
                                            v-model="marcaSelected"
                                            :items="listMarca"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            disabled
                                        ></v-combobox>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="6"
                                    >
                                        <label>Tipo de Produto</label>
                                        <v-combobox
                                            v-model="tipoProdutoSelected"
                                            :items="listTipoProduto"
                                            item-text="descricao"
                                            item-value="id"
                                            clearable
                                            outlined
                                            @change="getListParametros(); getListFamilias(false, '');"
                                        ></v-combobox>
                                    </v-col>

                                    <v-col 
                                        cols="12"
                                        lg="3"
                                        md="3"
                                        sm="6"
                                    >
                                        <label>Familía</label>
                                        <v-combobox
                                            v-model="familiaProdutoSelected"
                                            :items="listFamilias"
                                            item-text="codigoFamilia"
                                            item-value="id"
                                            clearable
                                            outlined
                                            @change="getListParametros()"
                                        >
                                            <template v-slot:item="{ item }">
                                                <span>{{ item.codigoFamilia }}</span>
                                                <v-spacer></v-spacer>
                                                <v-tooltip
                                                    v-if="item.obsoleto == 1"
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color="var(--color__warning)" large v-on="on">mdi-alert-rhombus</v-icon>
                                                    </template>
                                                    <span> {{ item.textoObsoleto  }} </span>
                                                </v-tooltip>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>

                                <v-divider></v-divider>

                                <v-row>
                                    <v-col 
                                        v-for="(itemParametro, index) in listParametro" :key="index"
                                        cols="6"
                                        lg="3"
                                        md="6"
                                        sm="6"
                                    >
                                        <label style="font-size: 12px;" v-if="itemParametro.descricao != null && itemParametro.descricao != ''">{{ itemParametro.descricao }}</label>
                                        <v-combobox
                                            v-if="itemParametro.descricao != null && itemParametro.descricao != '' && itemParametro.listParametroItem != null && itemParametro.listParametroItem.length > 0"
                                            v-model="itemParametro.itemSelected"
                                            :items="itemParametro.listParametroItem.filter(item => item.mostrar == true)"
                                            item-text="codigoDescricao"
                                            item-value="codigo"
                                            clearable
                                            outlined
                                            @change="verificaListaParametro();verificaParametrosPreenchidos();"
                                        >
                                            <template v-slot:item="{ item }">
                                                <span>{{ item.codigoDescricao }}</span>
                                                <v-spacer></v-spacer>
                                                <v-tooltip
                                                    v-if="item.obsoleto == 1"
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color="var(--color__warning)" large v-on="on">mdi-alert-rhombus</v-icon>
                                                    </template>
                                                    <span> {{ item.textoObsoleto  }} </span>
                                                </v-tooltip>
                                            </template>                
                                        </v-combobox>
                                    </v-col>
                                </v-row>

                                <v-row
                                    v-if="listProduto.length != 0"
                                >
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-list shaped>
                                            <v-subheader>{{ listProduto.length != 0 ? 'Lista de Produtos' : '' }}</v-subheader>

                                            <v-list-item-group
                                            >
                                                <v-list-item
                                                    v-for="(item, index) in listProduto"
                                                    :key="index"
                                                >
                                                    <v-list-item-avatar>
                                                        <v-img :src="item.imagem"></v-img>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content style="flex: 4;">
                                                        <v-list-item-title><strong> {{ item.codigoCompleto }} </strong></v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.descricao }}</v-list-item-subtitle>
                                                        
                                                        <!-- OUTROS CUSTOS -->
                                                        <v-list-item-subtitle 
                                                            v-if="isUsuarioInterno && item.importado == 1"
                                                            style="margin-top: 5px;"
                                                        >
                                                            <div 
                                                                v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1"
                                                                style="width: 25%; display: flex; flex-direction: row;"
                                                            >
                                                                <div style="flex: 3"> <span><strong>Custo Base</strong></span> </div>
                                                                <div style="flex: 3; text-align: left;"> <span>{{ item.simboloCustoBase }} {{ item.precoCustoProdutoSemMargemFormatted }}</span> </div>
                                                            </div>
                                                            <div 
                                                                v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1"
                                                                style="width: 25%; display: flex; flex-direction: row;"
                                                            >
                                                                <div style="flex: 3"> <span><strong>Margem</strong></span> </div>
                                                                <div style="flex: 3; text-align: left;"> <span>{{ item.margemFormatted }} %</span> </div>
                                                            </div>
                                                            <div v-if="mostrarValorICP" style="width: 25%; display: flex; flex-direction: row;">
                                                                <div style="flex: 3"> <span><strong>Valor ICP</strong></span> </div>
                                                                <div style="flex: 3; text-align: left;"> <span>{{ item.valorICPFormatted }}</span> </div>
                                                            </div>
                                                        </v-list-item-subtitle>

                                                        <!-- SUB-ESTRUTURAS -->
                                                        <v-list-item-subtitle 
                                                            v-if="mostrarSubEstruturas(item)"
                                                            style="margin-top: 5px;"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;">
                                                                <div style="flex: 6"> <span><strong>Subestruturas Utilizadas</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>Custo</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>Custo + Margem</strong></span> </div>
                                                            </div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="mostrarSubEstruturas(item)"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;" v-for="(itemSub, index) in item.listSubEstrutura.filter(sub => sub.regraAplicada == true)" :key="index">
                                                                <div style="flex: 6"> <span>{{ itemSub.nome }}</span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span>{{ itemSub.custoFormatted }}</span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span>{{ itemSub.custoComMargemFormatted }}</span> </div>
                                                                <br />
                                                            </div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="mostrarSubEstruturas(item)"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;">
                                                                <div style="flex: 6"> <span><strong>Totais</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalSubEstruturas(item).valorTotal }}</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalSubEstruturas(item).valorTotalComMargem }}</strong></span> </div>
                                                            </div>
                                                        </v-list-item-subtitle>



                                                        <!-- PARAMETROS -->
                                                        <v-list-item-subtitle 
                                                            v-if="mostrarParametros(item)"
                                                            style="margin-top: 5px;"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;">
                                                                <div style="flex: 9"> <span><strong>Parametros Utilizados</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>Custo</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>Valor ICP</strong></span> </div>
                                                            </div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="mostrarParametros(item)"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;" v-for="(itemParam, index) in listParametro.filter(itemParam => itemParam.itemSelected != null && itemParam.itemSelected != undefined)" :key="index">
                                                                <div style="flex: 9"> <span>{{ itemParam.itemSelected.codigo }}</span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span>{{ itemParam.itemSelected.precoCustoParametroItemFormatted }}</span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span>{{ itemParam.itemSelected.valorICPFormatted }}</span> </div>
                                                                <br />
                                                            </div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="mostrarParametros(item)"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;">
                                                                <div style="flex: 9"> <span><strong>Totais</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, false).valorTotal }}</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, false).valorTotalICPFormatted }}</strong></span> </div>
                                                            </div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            v-if="mostrarParametros(item)"
                                                        >
                                                            <div style="width: 50%; display: flex; flex-direction: row;">
                                                                <div style="flex: 9"> <span><strong>Custo Base + Custo Parametros</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> <span><strong>{{ valorTotalParametrosSelecionados(item, true).valorTotal }}</strong></span> </div>
                                                                <div style="flex: 3; text-align: right;"> &nbsp; </div>
                                                            </div>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <v-list-item-content>
                                                        <v-list-item-title style="color: var(--color__main) !important; font-size: 25px;"><strong> {{ precoFinalProduto(item) }} </strong></v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action>
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn 
                                                                    icon
                                                                    v-on="on"
                                                                    @click="adicionarItemCotacao(item)"
                                                                >
                                                                    <v-icon>mdi-plus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Adicionar Produto</span>
                                                        </v-tooltip>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn 
                                                                    icon
                                                                    v-on="on"
                                                                    @click="detalhesProduto(item)"
                                                                >
                                                                    <v-icon>mdi-information</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Clique para mais informações</span>
                                                        </v-tooltip>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-col>
                                </v-row>

                                <v-divider></v-divider>
                                
                                <v-dialog
                                    v-model="mostrarDetProduto"
                                    transition="dialog-top-transition"
                                    width="900"
                                    :fullscreen="$vuetify.breakpoint.mobile"
                                >
                                
                                    <v-card v-if="produtoSelected != null && produtoSelected != undefined">
                                        <v-card-title class="text-h5 lighten-2">
                                            Detalhes do Produto: {{ produtoSelected != null ? produtoSelected.codigoCompleto : '' }}
                                        </v-card-title>

                                        <v-card-text style="margin-top: 20px;">
                                            <v-row>
                                                <v-col 
                                                    cols="12"
                                                    lg="4"
                                                    md="6"
                                                    sm="12"
                                                >
                                                    <v-row>
                                                        <v-col 
                                                            style="text-align: center;"
                                                            cols="12"
                                                        >
                                                            <v-card>
                                                                <label>Imagem do Produto</label>
                                                                <v-avatar :tile="true" size="100%">
                                                                    <canvas id="canvas" hidden></canvas>
                                                                    <img :src="produtoSelected.imagem" alt="avatar" style="height: 250px" />
                                                                </v-avatar>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col 
                                                    cols="12"
                                                    lg="8"
                                                    md="6"
                                                    sm="12"
                                                >
                                                    <v-row>
                                                        <v-col 
                                                            cols="12"
                                                            lg="12"
                                                            md="12"
                                                            sm="6"
                                                        >
                                                            <label>Descrição</label>
                                                            <v-textarea
                                                                v-model="produtoSelected.descricao"
                                                                readonly
                                                                single-line
                                                                rows="3"
                                                                row-height="30"
                                                                outlined
                                                            >
                                                            </v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col 
                                                            cols="12"
                                                            lg="12"
                                                            md="12"
                                                            sm="6"
                                                        >
                                                            <label>Especificações</label>
                                                            <v-simple-table
                                                                fixed-header
                                                            >
                                                                <template v-slot:default>
                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(itemEspecificacao, index) in listEspecificacao" :key="index"
                                                                        >
                                                                            <td><strong>{{ itemEspecificacao.chave }}</strong></td>
                                                                            <td>{{ itemEspecificacao.descricao }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <div style="width: 100%; text-align: center;">

                                                <v-btn
                                                    class="mx-2 botaoDispensar"
                                                    outlined
                                                    @click="OK()"
                                                >
                                                    Fechar
                                                </v-btn>
                                                <v-btn
                                                    class="mx-2"
                                                    outlined
                                                    @click="adicionarItemCotacao(produtoSelected); OK();"
                                                >
                                                    <v-icon left>mdi-plus</v-icon> Adicionar Produto
                                                </v-btn>
                                            </div>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >

                                        <v-row
                                            v-for="(itemCotacao, index) in orcamentoRequest.listItemsOrcamento" :key="index"
                                        >
                                            <v-col 
                                                cols="12"
                                            >
                                                <v-expansion-panels
                                                    style="display: block;"
                                                    popout
                                                >
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header
                                                            :key="index"
                                                        >
                                                            <v-row>
                                                                <v-col 
                                                                    cols="2"
                                                                    lg="1"
                                                                    md="1"
                                                                    sm="1"
                                                                >
                                                                    <v-list-item-avatar>
                                                                        <v-img :src="itemCotacao.imagem"></v-img>
                                                                    </v-list-item-avatar>
                                                                </v-col>

                                                                <v-col 
                                                                    cols="10"
                                                                    lg="5"
                                                                    md="12"
                                                                    sm="12"
                                                                >
                                                                    <label>{{ itemCotacao.codigoProduto }} </label> 
                                                                    <v-tooltip
                                                                        v-if="itemCotacao.certificacaoEX == 1" 
                                                                        top
                                                                    >
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon
                                                                                v-if="itemCotacao.certificacaoEX == 1" 
                                                                                v-on="on"
                                                                                color="var(--color__warning)"
                                                                            >
                                                                                mdi-certificate-outline
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Produto com Certificação EX</span>
                                                                    </v-tooltip>
                                                                    <br />
                                                                    <label style="font-weight: normal !important;">{{ itemCotacao.descricaoProduto }}</label> 
                                                                </v-col>

                                                                <v-col
                                                                    style="display: flex; align-items: center;"
                                                                    cols="11"
                                                                    lg="2"
                                                                    md="3"
                                                                    sm="3"
                                                                >
                                                                    <v-text-field
                                                                        style="height: 30px;"
                                                                        label="Desconto"
                                                                        v-model="itemCotacao.descontoUsuarioFormatted"
                                                                        suffix=" %"
                                                                        v-formatMoney="percFormat"
                                                                        outlined
                                                                        dense
                                                                        @blur="calcularTotal()"
                                                                        @keyup.prevent=""
                                                                        @click.stop=""
                                                                    >
                                                                    </v-text-field>
                                                                        
                                                                </v-col>

                                                                <v-col  
                                                                    style="display: flex; align-items: center;"
                                                                    cols="12"
                                                                    lg="2"
                                                                    md="4"
                                                                    sm="5"
                                                                >
                                                                    <v-btn-toggle
                                                                    >
                                                                        <v-btn
                                                                            class="btnCarrinho"
                                                                            @click.stop=""
                                                                            @click="atualizarQtde('-', itemCotacao)"
                                                                            outlined
                                                                            x-small
                                                                            fab
                                                                            dense
                                                                        >
                                                                            <v-icon>mdi-minus</v-icon>
                                                                        </v-btn>

                                                                        <v-text-field
                                                                            name="campoQtde"
                                                                            style="height: 30px;"
                                                                            v-model="itemCotacao.qtde"
                                                                            single-line
                                                                            outlined
                                                                            dense
                                                                            v-mask="'######'"
                                                                            @blur="calcularTotal(); validarCampanhaCredito(true);"
                                                                            @keyup.prevent=""
                                                                            @click.stop=""
                                                                        >
                                                                        </v-text-field>
                                                                        
                                                                        <v-btn
                                                                            class="btnCarrinho"
                                                                            @click.stop=""
                                                                            @click="atualizarQtde('+', itemCotacao)"
                                                                            outlined
                                                                            x-small
                                                                            fab
                                                                            dense
                                                                        >
                                                                            <v-icon>mdi-plus</v-icon>
                                                                        </v-btn>
                                                                    </v-btn-toggle>
                                                                </v-col>

                                                                <v-col 
                                                                    style="text-align: center; display: flex; align-items: center;"
                                                                    cols="12"
                                                                    lg="2"
                                                                    md="3"
                                                                    sm="4"
                                                                >
                                                                    <div 
                                                                        style="display: flex; flex-direction: row; width: 100%; align-items: center;"
                                                                    >
                                                                        <div style="display: flex; flex-direction: column;">
                                                                            <label 
                                                                                style="font-size: 25px; line-height: 30px;"
                                                                            >
                                                                                <!-- {{ itemCotacao.precoUnitarioTotalComImpostos }} -->
                                                                                {{ mostrarBotaoImposto ? itemCotacao.precoCustoTotal : itemCotacao.precoUnitarioTotalComImpostos }}
                                                                            </label>
                                                        
                                                                            <v-tooltip 
                                                                                top
                                                                            >
                                                                                <template v-slot:activator="{ on }">
                                                                                    <label 
                                                                                        v-on="on"
                                                                                        style="font-size: 10px;"
                                                                                    >
                                                                                        {{ itemCotacao.valorUnitario }}
                                                                                    </label>
                                                                                </template>
                                                                                <span>Valor Unitário (Total / Qtde)</span>
                                                                            </v-tooltip>
                                                                        </div>

                                                                        <div style="display: flex; flex-direction: column;">
                                                
                                                                            <v-tooltip
                                                                                v-if="mostrarEdicaoValorItem()"
                                                                                top
                                                                            >
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-btn
                                                                                        v-if="mostrarEdicaoValorItem()"
                                                                                        v-on="on"
                                                                                        class="btnEdit"
                                                                                        @click.stop=""
                                                                                        outlined
                                                                                        small
                                                                                        fab
                                                                                        dense
                                                                                        @click="showDialogValorItem(itemCotacao)"
                                                                                    >
                                                                                        <v-icon>mdi-calculator-variant-outline</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <span>Editar Valor</span>
                                                                            </v-tooltip>

                                                                            <v-tooltip
                                                                                top
                                                                            >
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-btn
                                                                                        v-on="on"
                                                                                        class="btnEdit"
                                                                                        @click.stop=""
                                                                                        outlined
                                                                                        small
                                                                                        fab
                                                                                        dense
                                                                                        @click="clonarItem(itemCotacao)"
                                                                                    >
                                                                                        <v-icon>mdi-content-duplicate</v-icon>
                                                                                    </v-btn>
                                                                                </template>
                                                                                <span>Clonar Item</span>
                                                                            </v-tooltip>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content style="font-size: 12px">

                                                            <v-row 
                                                                v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1"
                                                                style="border-top: solid 1px gray !important;"
                                                            >
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Margem Efetiva</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.margemEfetiva }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="itemCotacao.certificacaoEX == 1">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Valor Certificação</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.valorCertificacaoEXFormatted }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Preço (sem impostos)</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <!-- <label>{{ itemCotacao.precoSemImpostos }}</label> -->
                                                                    <label>{{ itemCotacao.precoAplicadoManualmente == 1 ? itemCotacao.precoSemImpostosOriginal : itemCotacao.precoSemImpostos }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Preço (com impostos)</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.precoUnitarioComImpostos }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>NCM</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.ncm }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Origem</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.origem }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="isTipoKeyAccount == true">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Key Account</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.keyAccount == 1 ? 'SIM' : 'NÃO' }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>ICMS</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.aliqICMSFormatted }}% - {{ itemCotacao.valICMSFormatted }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>PIS</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.aliqPISFormatted }}% - {{ itemCotacao.valPISFormatted }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>COFINS</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.aliqCOFINSFormatted }}% - {{ itemCotacao.valCOFINSFormatted }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>IPI</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.aliqIPIFormatted }}% - {{ itemCotacao.valorIPIFormatted }} (a incluir)</label>
                                                                </v-col>
                                                            </v-row>
                                                
                                                            <v-row
                                                                v-if="itemCotacao.descontoPorQtdeAplicado && isUsuarioInterno"
                                                            >
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label><strong>Desconto</strong></label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>
                                                                        {{ itemCotacao.descontoPorQtdePercentual }}
                                                                    </label>
                                                                </v-col>
                                                            </v-row>
                                                
                                                            <v-row
                                                                v-if="mostrarDescontoDistribuidor"
                                                            >
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label><strong>Desconto Distribuidor</strong></label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label 
                                                                        v-if="distribuidorConfig != null && distribuidorConfig != undefined && distribuidorConfig.listDesconto != null && distribuidorConfig.listDesconto != undefined && distribuidorConfig.listDesconto.length > 0"
                                                                    >
                                                                        {{ getDescontoDistribuidor(itemCotacao) }} %
                                                                    </label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Qtde em Estoque</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.qtdeEstoque }}</label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row v-if="impostoCalculado">
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label>Prazo de Entrega</label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label>{{ itemCotacao.prazoEntrega }} {{ itemCotacao.prazoEntregaDescricao }}</label>
                                                                    <v-tooltip 
                                                                        top
                                                                        v-if="mostrarEdicaoPrazoEntrega(itemCotacao)"
                                                                    >
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                v-if="mostrarEdicaoPrazoEntrega(itemCotacao)"
                                                                                v-on="on"
                                                                                class="btnEdit"
                                                                                @click.stop=""
                                                                                outlined
                                                                                small
                                                                                fab
                                                                                dense
                                                                                @click="showDialogPrazoEntrega(itemCotacao)"
                                                                            >
                                                                                <v-icon>mdi-pencil</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Editar prazo de entrega</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                
                                                            <v-row
                                                                v-if="mostrarCampanhas"
                                                            >
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label><strong>Descontos aplicados por Campanhas</strong></label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <label 
                                                                        v-if="listCampanhaEstoque != null && listCampanhaEstoque != undefined && listCampanhaEstoque.length > 0"
                                                                    >
                                                                        Estoque - {{ itemCotacao.descontoCampanhaEstoque }} %
                                                                    </label>

                                                                    <br v-if="listCampanhaEstoque != null && listCampanhaEstoque != undefined && listCampanhaEstoque.length > 0" />

                                                                    <label 
                                                                        v-if="mostrarCampanhaCredito"
                                                                    >
                                                                        Crédito - {{ mostrarCampanhaCredito ? listCampanhaCredito[0].descontoFormatted + ' %' : '' }}
                                                                    </label>

                                                                    <br v-if="mostrarCampanhaCredito" />
                                                                    
                                                                    <label 
                                                                        v-if="itemCotacao.descontoCampanhaOuroPrataDescricao != null && itemCotacao.descontoCampanhaOuroPrataDescricao != undefined && 
                                                                            itemCotacao.descontoCampanhaOuroPrata != null && itemCotacao.descontoCampanhaOuroPrata != undefined && itemCotacao.descontoCampanhaOuroPrata > 0"
                                                                    >
                                                                        {{ itemCotacao.descontoCampanhaOuroPrataDescricao }} - {{ itemCotacao.descontoCampanhaOuroPrata }} %
                                                                    </label>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <v-switch 
                                                                        v-model="itemCotacao.entregaProgramada"
                                                                        label="Entrega Programada?"
                                                                        :value=1
                                                                        color="var(--color__main)"
                                                                    >
                                                                    </v-switch>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <v-menu
                                                                        v-if="itemCotacao.entregaProgramada == 1"
                                                                        ref="mnuDataEntrega"
                                                                        v-model="itemCotacao.mnuDataEntrega"
                                                                        :close-on-content-click="false"
                                                                        transition="scale-transition"
                                                                        offset-y
                                                                        max-width="300px"
                                                                        min-width="auto"
                                                                    >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field
                                                                                v-model="itemCotacao.dataEntregaFormatted"
                                                                                prepend-inner-icon="mdi-calendar"
                                                                                v-mask="'##/##/####'"
                                                                                v-bind="attrs"
                                                                                outlined
                                                                                single-line
                                                                                v-on="on"
                                                                            ></v-text-field>
                                                                        </template>
                                                                        <v-date-picker
                                                                            v-model="itemCotacao.dataEntrega"
                                                                            no-title
                                                                            @input="formatarData(itemCotacao)"
                                                                            :min="dataAtual"
                                                                            locale="pt-br"
                                                                        ></v-date-picker>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <v-switch 
                                                                        v-model="itemCotacao.produtoAgrupado"
                                                                        label="Produto Agrupado?"
                                                                        :value=1
                                                                        color="var(--color__main)"
                                                                    >
                                                                    </v-switch>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <v-text-field
                                                                        v-if="itemCotacao.produtoAgrupado == 1"
                                                                        v-model="itemCotacao.tagAgrupamento" 
                                                                        single-line
                                                                        outlined
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row
                                                                v-if="isUsuarioInterno"
                                                            >
                                                                <v-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="6"
                                                                >
                                                                    <label><strong>Observações Internas</strong></label>
                                                                </v-col>
                                                                <v-col 
                                                                    cols="6"
                                                                    lg="12"
                                                                    md="6"
                                                                    sm="6"
                                                                >
                                                                    <v-textarea
                                                                        v-model="itemCotacao.observacao"
                                                                        single-line
                                                                        rows="3"
                                                                        row-height="30"
                                                                        outlined
                                                                    >
                                                                    </v-textarea>
                                                                </v-col>
                                                            </v-row>

                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                </v-row>

                            </v-card-text>

                        </v-card>
                        
                    </v-card-text>
                </v-col>

            </v-row>

            <v-row>
                <v-col 
                    style="margin-top: 15px; text-align: right;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-card-text>
                        <v-card
                            class="mx-auto"
                        >
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                <v-row>
                                    <v-col
                                        style="text-align: left;"
                                        cols="12"
                                        lg="8"
                                        md="7"
                                        sm="12"
                                    >
                                        Resumo da Cotação
                                    </v-col>
                                    <v-col
                                        style="text-align: right;"
                                        cols="12"
                                        lg="4"
                                        md="4"
                                        sm="12"
                                    >
                                        <v-btn
                                            v-if="mostrarBotaoImposto"
                                            class="mx-2"
                                            color="#aaa !important"
                                            @click="confirmarCalculoImpostos()"
                                        >
                                            Verificar Impostos e Disponibilidade
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text style="margin-top: 20px;">
                                
                                <v-row>
                                    <v-col
                                        v-if="mostrarFlagCampanhaCredito"
                                        style="display: flex; flex-direction: row-reverse;"
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >   
                                        <v-switch 
                                            v-model="abaterSaldoCampanhaCredito"
                                            :disabled="disableSaldoCampanhaCredito"
                                            label="Abater saldo da campanha de crédito?"
                                            color="var(--color__main)"
                                            :value=0
                                            @change="validarCampanhaCredito(true);"
                                        >
                                        </v-switch>
                                    </v-col>
                                    <v-col
                                        v-if="isTipoDistribuidor"
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    outlined
                                                    v-on="on"
                                                    @click="abrirDialogSaldoCampanha()"
                                                >
                                                    Visualizar Saldo de Campanha
                                                </v-btn>
                                            </template>
                                            <span>Visualizar saldo de campanha dos pedidos</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="9"
                                        md="6"
                                        sm="12"
                                    >
                                        <label style="color: var(--color__main) !important; font-size: 18px; text-align: right;">Total Cotação</label>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        lg="3"
                                        md="6"
                                        sm="12"
                                    >
                                        <label style="color: var(--color__main) !important; font-size: 18px;">{{ valorTotalFormatado }}</label>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-card-text>
                </v-col>
            </v-row>
        
            <v-dialog
                v-model="mostrarDetProduto"
                transition="dialog-top-transition"
                width="900"
                :fullscreen="$vuetify.breakpoint.mobile"
            >
            
                <v-card v-if="produtoSelected != null && produtoSelected != undefined">
                    <v-card-title class="text-h5 lighten-2">
                        Detalhes do Produto: {{ produtoSelected != null ? produtoSelected.codigoCompleto : '' }}
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="4"
                                md="6"
                                sm="12"
                            >
                                <v-row>
                                    <v-col 
                                        style="text-align: center;"
                                        cols="12"
                                    >
                                        <v-card>
                                            <label>Imagem do Produto</label>
                                            <v-avatar :tile="true" size="100%">
                                                <canvas id="canvas" hidden></canvas>
                                                <img :src="produtoSelected.imagem" alt="avatar" style="height: 250px" />
                                            </v-avatar>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col 
                                cols="12"
                                lg="8"
                                md="6"
                                sm="12"
                            >
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="6"
                                    >
                                        <label>Descrição</label>
                                        <v-textarea
                                            v-model="produtoSelected.descricao"
                                            readonly
                                            single-line
                                            rows="3"
                                            row-height="30"
                                            outlined
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="6"
                                    >
                                        <label>Especificações</label>
                                        <v-simple-table
                                            fixed-header
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr
                                                        v-for="(itemEspecificacao, index) in listEspecificacao" :key="index"
                                                    >
                                                        <td><strong>{{ itemEspecificacao.chave }}</strong></td>
                                                        <td>{{ itemEspecificacao.descricao }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <div style="width: 100%; text-align: center;">

                            <v-btn
                                class="mx-2 botaoDispensar"
                                outlined
                                @click="mostrarDetProduto = false"
                            >
                                Fechar
                            </v-btn>
                            <v-btn
                                class="mx-2"
                                outlined
                                @click="adicionarItemCotacao(produtoSelected); OK();"
                            >
                                <v-icon left>mdi-plus</v-icon> Adicionar Produto
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

        <ActionButtons
            :request="orcamentoRequest"
            :loading="loading"
            :showButtons="{
                cancel: true,
                save: mostrarBotaoGravar
            }"
            @cancel="cancel"
            @save="validarCotacao"
        />

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
        
        <DialogMessage 
            v-on:methodOKToCall="dialogMessage.methodOK"
            :showDialogMessage.sync="dialogMessage.show"
            :headerTitle="dialogMessage.headerText"
            :headerColor="dialogMessage.headerColor"
            :headerFontColor="dialogMessage.headerFontColor"
            :bodyText="dialogMessage.bodyText"
        />

        <DialogPrazoEntregaEdit 
            v-on:methodConfirmToCall="dialogPrazoEntrega.methodConfirm"
            :showDialogPrazoEntregaEdit.sync="dialogPrazoEntrega.show"
            :prazoEntrega="dialogPrazoEntrega.prazoEntrega"
            :prazoEntregaOriginal="dialogPrazoEntrega.prazoEntregaOriginal"
        />

        <DialogValorItemEdit 
            v-on:methodConfirmToCall="dialogValorItem.methodConfirm"
            :showDialogValorItemEdit.sync="dialogValorItem.show"
            :id="dialogValorItem.id"
            :codigoProduto="dialogValorItem.codigoProduto"
            :precoLista="dialogValorItem.precoLista"
            :valor="dialogValorItem.valor"
        />

        <DialogPedidosCampanha 
            :showDialogPedidosCampanha.sync="dialogSaldoCampanha.show"
            :idAux="dialogSaldoCampanha.idAux"
            :idDistribuidor="dialogSaldoCampanha.idDistribuidor"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogMessage from "@/components/Layout/DialogMessage";
    import DialogPrazoEntregaEdit from "@/components/Layout/DialogPrazoEntregaEdit";
    import DialogValorItemEdit from "@/components/Layout/DialogValorItemEdit";
    import DialogPedidosCampanha from "@/components/Layout/DialogPedidosCampanha";
    import moment from 'moment';
    import { 
        formaterDecimalBRServer, 
        formaterDecimalBR, 
        removeAcentos,
        formatDateToServer,
        formatarDataPadraoPtBR
    } from '@/utilities/Utils';
    import { 
        CONSULTAR_FABRICA_DESCRICAO, 
        LIST_TIPO_PEDIDO, 
        LIST_TIPO_CLIENTE 
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog,
            DialogMessage,
            DialogPrazoEntregaEdit,
            DialogValorItemEdit,
            DialogPedidosCampanha
        },

        data: () => ({
            codigoProduto: "",

            validForm: true,

            orcamentoRequest: {
                id: 0,
                idFormatted: "",
                dataCadastroFormatted: "",
                statusDescricao: "",
                tipoCliente: null,
                nomeCliente: "",
                condicaoPagamentoDescricao: "",
                formaPagamentoDescricao: "",
                tipoFreteDescricao: "",
                valorTotalFormatted: "R$ 0,00",
                listItemsOrcamento: []
            },

            orcamentoRequestClone: {},

            descontoUsuarioFormatado: "0,00",
            valorTotalFormatado: "R$ 0,00",
            valorTotalIPI: 0,
            valorTotalSemDescontoCampanha: "R$ 0,00",

            tipoClienteSelected: null,

            listTipoCliente: LIST_TIPO_CLIENTE,

            listTipoProduto: [],
            listMarca: [],
            listGrandeza: [],
            distribuidorSelected: null,
            listDistribuidor: [],
            loadingDistribuidor: false,
            listClientes: [],
            loadingClientes: false,

            grandezaSelected: null,
            marcaSelected: null,
            tipoProdutoSelected: null,
            familiaProdutoSelected: null,
            produtoSelected: null,
            descricaoProdutoCompleta: "",
            listCondicaoPagamento: [],
            listFormaPagamento: [],
            listTipoFrete: [],
            
            listDescontos: [],
            listCampanhaEstoque: [],
            listCampanhaCredito: [],
            abaterSaldoCampanhaCredito: true,
            disableSaldoCampanhaCredito: false,
            elegivelParaCampanha: true,
            distribuidorConfig: null,
            listCampanhaOuroPrata: [],
            listCampanhaOuroPrataConsultada: [],

            listProduto: [],
            listParametro: [],
            listEspecificacao: [],

            mostrarDetProduto: false,
            precoCustoProdutoCalculado: 0,
            listaIDParametroItem: '', 

            jsonParametrosSelecionados: "",

            listFamilias: [],
            listFamiliasAux: [],

            impostoCalculado: false,
            descontoDistribuidor: "0",

            loading: false,
            
            consultarFabricaDescricao: CONSULTAR_FABRICA_DESCRICAO,

            listTipoPedido: LIST_TIPO_PEDIDO,

            configuracaoSistema: null,
            
            validations: {
                required: required,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogMessage: {
                show: false,
                headerText: "",
                headerColor: "",
                headerFontColor: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },

            textoDias: "dias úteis",

            dialogPrazoEntrega: {
                show: false,
                prazoEntrega: "0",
                prazoEntregaOriginal: "0",
                methodConfirm: ( () => {} )
            },

            dialogSaldoCampanha: {
                show: false,
                idAux: 0,
                idDistribuidor: "0"
            },

            dialogValorItem: {
                show: false,
                id: 0,
                codigoProduto: "",
                precoLista: "R$ 0,00",
                valor: "R$ 0,00",
                methodConfirm: ( () => {} )
            },

            og: Math.pow(10, 2),
            
            todosParametrosPreenchidos: false,

            entregaTurbo: 0,

            dataAtual: moment().clone().startOf('day').format('YYYY-MM-DD'),
        }),

        props: {
            id: {
                default: 0
            },

            clonar: {
                default: false
            },
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            isTipoDistribuidor () {
                return this.tipoClienteSelected != null && 
                       this.tipoClienteSelected != undefined && 
                       this.tipoClienteSelected.id == 2
            },
            
            isTipoKeyAccount() {
                return this.tipoClienteSelected != null && 
                       this.tipoClienteSelected != undefined && 
                       this.tipoClienteSelected.id == 5
            },

            checkTipoCliente () {
                return this.tipoClienteSelected != null && 
                       this.tipoClienteSelected != undefined && 
                       this.tipoClienteSelected.id != 2
            },

            mostrarDescontoDistribuidor() {

                let mostrarDescontoDistribuidor = false;

                if (this.distribuidorConfig != null && this.distribuidorConfig != undefined && this.distribuidorConfig.listDesconto != null && this.distribuidorConfig.listDesconto != undefined && this.distribuidorConfig.listDesconto.length > 0) {
                    mostrarDescontoDistribuidor = true
                }

                return mostrarDescontoDistribuidor;
            },

            mostrarCampanhaCredito() {
                return this.listCampanhaCredito != null && 
                       this.listCampanhaCredito != undefined && 
                       this.listCampanhaCredito.length > 0 && 
                       this.distribuidorConfig != null && 
                       this.distribuidorConfig != undefined && 
                       this.distribuidorConfig.valorCredito > 0;
            },

            mostrarBotaoConsultaSaldoCampanha() {

                if (this.isUsuarioInterno == true) {
                    return this.tipoClienteSelected != null && 
                        this.tipoClienteSelected.id == 2 && 
                        this.distribuidorConfig != null && 
                        this.distribuidorConfig != undefined;

                }
                else {
                    return true;
                }
            },

            mostrarCampanhas() {

                let mostrarCampanha = false;

                if (this.listCampanhaEstoque != null && this.listCampanhaEstoque != undefined && this.listCampanhaEstoque.length > 0) {
                    mostrarCampanha = true
                }
                else if (this.mostrarCampanhaCredito) {
                    mostrarCampanha = true
                }
                else if (this.listCampanhaOuroPrata != null && this.listCampanhaOuroPrata != undefined && this.listCampanhaOuroPrata.length > 0) {
                    mostrarCampanha = true
                }

                return mostrarCampanha;
            },

            mostrarBotaoGravar() {

                return this.impostoCalculado == true;
            },

            mostrarBotaoImposto() {

                return this.impostoCalculado == false;
            },

            mostrarCustoProduto() {
                return this.userLoggedGetters.perfil.visualizarCustoProduto == 1;
            },

            mostrarValorICP() {
                return this.userLoggedGetters.perfil.visualizarICP == 1;
            },

            temDistribuidorPai() {
                
                return this.userLoggedGetters.temDistribuidorPai == 1;
            },

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            },

            mostrarFlagCampanhaCredito() {
                return this.userLoggedGetters.perfil.visualizarFlagAbatimentoCampanhaCredito == 1;
            },
        },

        watch: {

            "loadingClientes"() {
                this.verificaKeyAccount();
            }
        },

        methods: {

            mostrarEdicaoPrazoEntrega(itemCotacao) {
                return this.isUsuarioInterno == true && itemCotacao.prazoEntrega != this.consultarFabricaDescricao;
            },

            mostrarEdicaoValorItem() {

                let idTipoCliente = 0;

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                    idTipoCliente = this.tipoClienteSelected.id;
                }

                return this.isUsuarioInterno == true && this.impostoCalculado == true && (idTipoCliente == 1 || idTipoCliente == 2 || idTipoCliente == 3 || idTipoCliente == 4);
            },

            async verificaParametrosPreenchidos() {
                
                let qtdeParametrosVazio = this.listParametro.filter(item => item.itemSelected == null || item.itemSelected == undefined).length;

                this.todosParametrosPreenchidos = qtdeParametrosVazio <= 0 || this.listParametro.length <= 0;
            },

            mostrarSubEstruturas(itemProduto) {
                let mostrar = false;

                if (itemProduto.listSubEstrutura != null && 
                    itemProduto.listSubEstrutura != undefined && 
                    itemProduto.listSubEstrutura.length > 0 &&
                    itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true).length > 0 &&
                    this.isUsuarioInterno == true) {
                    mostrar = true;
                }

                return mostrar;
            },

            mostrarParametros(itemProduto) {
                return this.isUsuarioInterno == true &&
                       this.userLoggedGetters.perfil.visualizarCustoProduto == 1 &&
                       this.mostrarSubEstruturas(itemProduto) == false && 
                       this.listParametro != null && 
                       this.listParametro != undefined && 
                       this.listParametro.length > 0;
            },

            valorTotalSubEstruturas(itemProduto) {

                let valorTotal = 0;
                let valorTotalComMargem = 0;
                let margemSubEstrutura = 0;

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    margemSubEstrutura = 1 - ( parseFloat(this.familiaProdutoSelected.margem) / 100 );
                }

                itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true).forEach(itemSub => {
                    valorTotal += parseFloat(itemSub.custo);
                });

                valorTotal =  Math.floor(valorTotal * this.og) / this.og;
                valorTotalComMargem =  Math.floor( ( valorTotal / margemSubEstrutura) * this.og) / this.og;

                return { 
                    valorTotal: `R$ ${formaterDecimalBR(valorTotal)}`,
                    valorTotalComMargem: `R$ ${formaterDecimalBR(valorTotalComMargem)}`,
                };
            },

            valorTotalParametrosSelecionados(itemProduto, somaCustoBase) {

                let valorTotal = 0;
                let valorTotalICP = 0;
                let moedaICP = itemProduto.simboloICP;
                let idCambioICP = itemProduto.idCambioICP;

                this.listParametro.forEach(itemParam => {
                    if (itemParam.itemSelected != null && itemParam.itemSelected != undefined) {
                        valorTotal += parseFloat(itemParam.itemSelected.precoCustoParametroItem);
                        valorTotalICP += parseFloat(itemParam.itemSelected.valorICP);

                        if (moedaICP == "" && valorTotalICP > 0) {

                            if (itemParam.itemSelected.cambioICPSelected != null && itemParam.itemSelected.cambioICPSelected != undefined && itemParam.itemSelected.cambioICPSelected.id != 0) {
                                idCambioICP = itemParam.itemSelected.cambioICPSelected.id;
                                moedaICP = itemParam.itemSelected.cambioICPSelected.simbolo;
                            }
                        }
                    }
                });

                if (somaCustoBase == true) {
                    valorTotal += parseFloat(itemProduto.precoCustoProdutoSemMargem ? itemProduto.precoCustoProdutoSemMargem : 0);
                    valorTotalICP += parseFloat(itemProduto.valorICP ? itemProduto.valorICP : 0);
                }

                valorTotal =  Math.floor(valorTotal * this.og) / this.og;
                valorTotalICP =  Math.floor(valorTotalICP * this.og) / this.og;

                return { 
                    valorTotal: `R$ ${formaterDecimalBR(valorTotal)}`,
                    idCambioICP,
                    valorTotalICP,
                    valorTotalICPFormatted: `${moedaICP} ${formaterDecimalBR(valorTotalICP)}`,
                };
            },

            async montaJsonListaParametrosSelecionados() {

                this.jsonParametrosSelecionados = `{`;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        this.jsonParametrosSelecionados += `"${removeAcentos(itemParametro.itemSelected.parametro.toString().replaceAll(" ", ""))}":"${itemParametro.itemSelected.codigo}",`;
                    }
                });

                this.jsonParametrosSelecionados = this.jsonParametrosSelecionados.substring(0, this.jsonParametrosSelecionados.length - 1);

                this.jsonParametrosSelecionados += `}`;
            },

            async verificaListaParametro() {

                await this.montaJsonListaParametrosSelecionados();

                await this.listParametro.forEach(async parametro => {

                    await parametro.listParametroItem.forEach(async item => {

                        if (item.mostrar == null || item.mostrar == undefined) {
                            item.mostrar = true;
                        }

                        if (item.expressaoRegular != null && item.expressaoRegular != undefined && item.expressaoRegular != '') {

                            let expr = item.expressaoRegular.toString().replaceAll("=", "==");

                            // eslint-disable-next-line no-undef
                            let expression = compileExpression(expr);

                            let result = expression(JSON.parse(this.jsonParametrosSelecionados));
                            item.mostrar = result == 1;
                        }
                    });
                });
                
                //VALIDA SE TEM ALGUM PARAMETRO SELECIONADO INVÁLIDO
                this.listParametro.forEach(async parametro => {

                    if (parametro.itemSelected && parametro.itemSelected != null && parametro.itemSelected != undefined) {

                        let existeNaLista = parametro.listParametroItem.filter(item => item.mostrar == true && item.id == parametro.itemSelected.id)[0];

                        if (existeNaLista == null || existeNaLista == undefined || existeNaLista.length == 0) {
                            parametro.itemSelected = null;
                            this.showToast("error", "Aviso!", `Por favor escolha uma nova opção para ${parametro.descricao}`);
                        }
                    }
                });

                this.getListProduto(); 
                this.calcularPrecoCustoProduto();
            },

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1: //ATIVO
                        color = 'var(--color__main)';
                        break;

                    case 2: //CANCELADO
                        color = 'var(--color__red)';
                        break;

                    case 3: //CONVERTIDO EM PEDIDO
                        color = 'var(--color__blue)';
                        break;

                    case 4: //NEGADO
                        color = 'var(--color__red)';
                        break;
                
                    default:
                        break;
                }

                return color
            },

            async clearFields() {
                this.tipoProdutoSelected = null;
                this.produtoSelected = null;
                this.listTipoProduto = [];
                this.listParametro = [];
                this.listProduto = [];
            },

            async getListTipoProduto() {

                this.showLoading();

                this.tipoProdutoSelected = null;
                
                let tipoProdutoFilter = {
                    idGrandeza: 0,
                    idMarca: 0
                }

                if (this.grandezaSelected != null && this.grandezaSelected != undefined) {
                    tipoProdutoFilter.idGrandeza = this.grandezaSelected.id;
                }

                if (this.marcaSelected != null && this.marcaSelected != undefined) {
                    tipoProdutoFilter.idMarca = this.marcaSelected.id;
                }

                this.listTipoProduto = await this.$store.dispatch("tipoProdutoModule/ListByFilter", tipoProdutoFilter);

                this.hideLoading();
            },
            
            async getListParametros() {

                this.getListProduto();

                this.showLoading();

                this.listParametro = [];

                let listaProdutoBaseFilter = {
                    idTipoProduto: 0,
                    idProdutoFamilia: 0,
                }

                if (this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idProdutoFamilia = this.familiaProdutoSelected.id;
                }

                this.listParametro = await this.$store.dispatch("produtoModule/ListarParametrosByTipoProduto", listaProdutoBaseFilter);

                //VERIFICAR SE O PRIMEIRO PARAMETROS TEM SOMENTE UMA OPÇÃO E PREENCHE AUTOMATICAMENTE
                if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {
                    let itemParametro = this.listParametro[0];
                    let listItemParametro = itemParametro.listParametroItem.filter(item => item.mostrar == true);

                    if (listItemParametro != null && listItemParametro != undefined && listItemParametro.length == 1) {
                        itemParametro.itemSelected = listItemParametro[0];
                    }
                }

                this.hideLoading();
            },

            async verificarProdutoColado() {

                if (this.listFamiliasAux.length <= 0) {
                    let codigoProduto = ''
                    for (const c of this.codigoProduto) {
                        codigoProduto += c;
                        await this.getListFamilias(true, codigoProduto);
                    }
                }
            },

            async getListFamilias(filtrarPorCodigo, codigoProduto) {

                this.familiaSelected = null;

                if (codigoProduto == null || codigoProduto == undefined || codigoProduto == '') {
                    codigoProduto = this.codigoProduto;
                }

                let listaProdutoBaseFilter = {
                    idTipoProduto: 0,
                    codigoProduto: codigoProduto
                }

                if (filtrarPorCodigo == false && this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                this.listFamilias = await this.$store.dispatch("produtoModule/ListarFamilias", listaProdutoBaseFilter);

                if (filtrarPorCodigo == true && this.listFamilias.length > 0) {
                    this.listFamiliasAux = [...this.listFamilias]

                    let familia = this.listFamiliasAux[0]

                    if (familia != null && familia != undefined) {
                        
                        let grandezaSelected = this.listGrandeza.filter(grd => grd.id == familia.idGrandeza)[0];
                        this.grandezaSelected = grandezaSelected;
                        
                        await this.getListTipoProduto();

                        let tipoProdutoSelected = this.listTipoProduto.filter(tip => tip.id == familia.idTipoProduto)[0]
                        this.tipoProdutoSelected = tipoProdutoSelected;

                        let familiaProdutoSelected = this.listFamilias.filter(fam => fam.id == familia.id)[0]
                        this.familiaProdutoSelected = familiaProdutoSelected;

                        this.$forceUpdate();

                        await this.getListParametros();
                    }
                }
                else {
                    if (this.listFamiliasAux.length > 0) {

                        let familia = this.listFamiliasAux[0]
                        let lengthCodigoFamilia = familia.codigoFamilia.toString().length

                        let lengthCodigoParametro = 0;
                        this.listParametro.forEach(item => {
                            if (item.itemSelected != null && item.itemSelected != undefined) {
                                lengthCodigoParametro += item.itemSelected.codigo.toString().length;
                            }
                        })

                        let codigoProduto = this.codigoProduto.substring(lengthCodigoFamilia + lengthCodigoParametro)

                        let codigoParametro = ""
                        for (const c of codigoProduto) {
                            codigoParametro += c.toString();

                            let index = this.listParametro.length - this.listParametro.filter(item => item.itemSelected == null || item.itemSelected == undefined).length;

                            if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {

                                let parametroSelected = this.listParametro[index].listParametroItem.filter(item => item.mostrar == true && item.codigo.toString().toLowerCase() == codigoParametro.toString().toLowerCase())[0]

                                if (parametroSelected != null && parametroSelected != undefined) {
                                    codigoParametro = "";
                                    this.listParametro[index].itemSelected = parametroSelected;
                                    this.verificaListaParametro(true);
                                    this.verificaParametrosPreenchidos();
                                }
                            }
                        }
                    }
                }
            },
            
            async getListProduto() {

                this.showLoading();

                this.produtoSelected = null;

                let codigoProduto = this.codigoProduto;

                if (this.listFamiliasAux.length > 0) {
                    codigoProduto = ""
                }

                let listaProdutoBaseFilter = {
                    codigoProduto,
                    idProdutoFamilia: 0,
                    idTipoProduto: 0,
                    listCodigoParametroItem: ["'0'"],
                    listIdParametroItem: [0]
                }

                if (this.tipoProdutoSelected != null && this.tipoProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idTipoProduto = this.tipoProdutoSelected.id;
                }

                if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                    listaProdutoBaseFilter.idProdutoFamilia = this.familiaProdutoSelected.id;
                }

                this.listaIDParametroItem = "";

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        listaProdutoBaseFilter.listCodigoParametroItem.push(`'${itemParametro.itemSelected.codigo}'`);
                        listaProdutoBaseFilter.listIdParametroItem.push(itemParametro.itemSelected.id);
                        this.listaIDParametroItem += `${itemParametro.itemSelected.codigo},`
                    }
                });

                this.listaIDParametroItem = this.listaIDParametroItem.substring(0, this.listaIDParametroItem.length - 1);

                this.listProduto = await this.$store.dispatch("produtoModule/ListarProdutoBase", listaProdutoBaseFilter);

                if (this.codigoProduto != null && this.codigoProduto != undefined && this.codigoProduto != "" && this.listFamiliasAux.length <= 0) {
                    this.grandezaSelected = null;
                    this.tipoProdutoSelected = null;
                    this.familiaProdutoSelected = null;
                    this.listParametro = [];
                }

                if (this.listProduto.length <= 0) {
                    this.verificarProdutoColado();
                }
                else {
                    this.verificaParametrosPreenchidos();
                }

                this.hideLoading();
            },

            async detalhesProduto(itemProduto) {
                this.produtoSelected = itemProduto;

                if (this.produtoSelected != null && this.produtoSelected != undefined) {

                    let response = await this.$store.dispatch("produtoModule/GetById", this.produtoSelected.idProduto);
                    this.listEspecificacao = response.result.listEspecificacaoResponse;

                    this.mostrarDetProduto = true;
                }
            },

            async calcularPrecoCustoProduto() {

                this.montaJsonListaParametrosSelecionados();

                let precoCusto = 0;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        precoCusto = (parseFloat(precoCusto) + parseFloat(itemParametro.itemSelected.precoCustoParametroItem)).toFixed(2);
                    }
                });

                this.precoCustoProdutoCalculado = precoCusto;
            },

            consultarProdutoKeyAccount(codigoProduto) {

                let result = {
                    keyAccount: false,
                    preco: 0
                };

                if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {
                    let produtoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto == codigoProduto)[0];

                    if (produtoKeyAccount != null && produtoKeyAccount != undefined) {
                        result.keyAccount = true;
                        result.preco = produtoKeyAccount.preco;
                    }
                }

                return result;
            },

            precoFinalProduto(itemProduto) {

                itemProduto.keyAccount = 0;
                let margemSubEstrutura = 0;

                let precoFinal = parseFloat(itemProduto.precoCustoProduto) + parseFloat(this.precoCustoProdutoCalculado);

                if (itemProduto.listSubEstrutura != null && itemProduto.listSubEstrutura != undefined && itemProduto.listSubEstrutura.length > 0) {

                    if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                        margemSubEstrutura = 1 - ( parseFloat(this.familiaProdutoSelected.margem) / 100 );
                    }

                    itemProduto.listSubEstrutura.forEach(itemSub => {
                        if (itemSub.expressaoRegular != null && itemSub.expressaoRegular != undefined && itemSub.expressaoRegular != "" && this.jsonParametrosSelecionados != null && this.jsonParametrosSelecionados != '') {

                            const expr = itemSub.expressaoRegular.toString().replaceAll("=", "==");

                            // eslint-disable-next-line no-undef
                            const expression = compileExpression(expr);

                            const result = expression(JSON.parse(this.jsonParametrosSelecionados));

                            if (result == 1) {

                                let custo = parseFloat(itemSub.custo);

                                itemSub.regraAplicada = true;
                                itemSub.custoComMargemFormatted = formaterDecimalBR(Math.floor( (custo / parseFloat(margemSubEstrutura)) * this.og) / this.og);
                                precoFinal = (parseFloat(precoFinal) + custo).toFixed(2);
                            }
                        }
                    });
                }

                const resultKeyAccount = this.consultarProdutoKeyAccount(itemProduto.codigoCompleto);

                if (resultKeyAccount.keyAccount == true) {
                    precoFinal = resultKeyAccount.preco;
                    itemProduto.keyAccount = 1;
                }

                if (margemSubEstrutura <= 0) {
                    margemSubEstrutura = 1;
                }

                const newPrecoFinal = Math.floor( (precoFinal / parseFloat(margemSubEstrutura)) * this.og) / this.og;

                return "R$ " + formaterDecimalBR(newPrecoFinal);
            },

            async calcularPrecoCustoProdutoSemMargem(itemProduto) {

                let valorCustoSemMargem = 0;
                let valorCustoSemMargemResultado = 0;

                this.listParametro.forEach(itemParametro => {
                    if (itemParametro.itemSelected && itemParametro.itemSelected != null && itemParametro.itemSelected != undefined) {
                        valorCustoSemMargem = (parseFloat(valorCustoSemMargem) + parseFloat(itemParametro.itemSelected.precoCustoParametroItemSemMargem)).toFixed(2);
                    }
                });

                let listSubEstrutura = itemProduto.listSubEstrutura.filter(sub => sub.regraAplicada == true);

                if (listSubEstrutura == null || listSubEstrutura == undefined || listSubEstrutura.length <= 0) {
                    valorCustoSemMargemResultado = (parseFloat(valorCustoSemMargem) + parseFloat(itemProduto.precoCustoProdutoSemMargem));

                    if (valorCustoSemMargemResultado <= 0) {

                        let resultKeyAccount = this.consultarProdutoKeyAccount(itemProduto.codigoCompleto);

                        if (resultKeyAccount.keyAccount == true) {
                            valorCustoSemMargemResultado = resultKeyAccount.preco;
                        }
                    }
                }
                else {
                    listSubEstrutura.forEach(item => {
                        let custoSubEstrutura = parseFloat(item.custo);
                        valorCustoSemMargemResultado += custoSubEstrutura;
                    })
                }
                
                valorCustoSemMargemResultado = Math.floor(valorCustoSemMargemResultado * this.og) / this.og;

                return valorCustoSemMargemResultado;
            },

            OK() {
                this.mostrarDetProduto = false;
            },

            async adicionarItemCotacao(item) {

                this.showLoading();
                
                const precoCusto = this.precoFinalProduto(item);
                let valorCustoSemMargem = await this.calcularPrecoCustoProdutoSemMargem(item);

                if (valorCustoSemMargem == null || valorCustoSemMargem == undefined || valorCustoSemMargem <= 0) {
                    valorCustoSemMargem = formaterDecimalBRServer(precoCusto);
                }

                const icpProduto = this.valorTotalParametrosSelecionados(item, true);

                let certificacaoEX = 0;
                let familiaEX = item.familiaEX;

                if (this.listParametro != null && this.listParametro != undefined && this.listParametro.length > 0) {
                    certificacaoEX = this.listParametro.filter(item => item.itemSelected != null && item.itemSelected != undefined && item.itemSelected.certificacaoEX == 1).length > 0 ? 1 : 0;
                }
                
                if (certificacaoEX == 0) {
                    certificacaoEX = item.certificacaoEX;
                }

                if (certificacaoEX == 1 && (familiaEX == null || familiaEX == undefined || familiaEX == '')) {
                    if (this.familiaProdutoSelected != null && this.familiaProdutoSelected != undefined) {
                        familiaEX = this.familiaProdutoSelected.codigoFamilia;
                    }
                }

                const custoProduto = parseFloat(formaterDecimalBRServer(precoCusto));

                let listaIDParametroItem = item.codigoCompleto;

                if (this.listaIDParametroItem  != null && this.listaIDParametroItem != undefined && this.listaIDParametroItem.toString().trim() != "") {
                    listaIDParametroItem = this.listaIDParametroItem;
                }

                this.orcamentoRequest.listItemsOrcamento.push({
                    id: 0,
                    item: this.orcamentoRequest.listItemsOrcamento.length + 1,
                    idProduto: item.idProduto,
                    idMarca: this.orcamentoRequest.idMarca,
                    imagem: item.imagem,
                    codigoProduto: item.codigoCompleto,
                    descricaoProduto: item.descricao,
                    listaIDParametroItem: listaIDParametroItem,
                    desconto: "0,00",
                    descontoUsuarioFormatted: "0,00",
                    qtde: 1,
                    importado: item.importado || 0,
                    margemEfetiva: 0,
                    precoCustoProdutoSemMargem: valorCustoSemMargem,
                    ncm: item.codigoNCM,
                    precoSemImpostos: custoProduto,
                    precoCusto: custoProduto,
                    valorCustoProduto: valorCustoSemMargem,
                    precoCustoFormatted: `R$ ${formaterDecimalBR(custoProduto)}`,
                    precoSemImpostosFormatted: `R$ ${formaterDecimalBR(custoProduto)}`,
                    precoUnitarioTotalComImpostos: `R$ ${formaterDecimalBR(custoProduto)}`,
                    descontoUsuario: 0,
                    descontoCampanhaEstoque: 0,
                    descontoCampanhCredito: 0,
                    descontoCampanhaOuroPrata: 0,
                    aliqPIS: item.aliqPIS,
                    aliqPISFormatted: item.aliqPISFormatted,
                    aliqCOFINS: item.aliqCOFINS,
                    aliqCOFINSFormatted: item.aliqCOFINSFormatted,
                    aliqICMS: item.aliqICMS,
                    aliqICMSFormatted: item.aliqICMSFormatted,
                    aliqIPI: item.aliqIPI,
                    aliqIPIFormatted: item.aliqIPIFormatted,
                    precoBruto: custoProduto,
                    valorUnitarioFormatted: custoProduto,
                    valorTotalFormatted: custoProduto,
                    descontoCampanha: false,
                    descontoAplicado: false,
                    idCambioICP: icpProduto.idCambioICP,
                    valorTotalICP: icpProduto.valorTotalICP,
                    familiaEX,
                    certificacaoEX,
                    valorCertificacaoEX: 0,
                    valorCertificacaoEXFormatted: 0,
                    keyAccount: item.keyAccount
                });
                
                await this.carregarDescontos(false);

                this.hideLoading();
            },

            async getLists() {
                
                this.listMarca = await this.$store.dispatch("marcaModule/List");

                let listGrandeza = await this.$store.dispatch("grandezaModule/ListOnlyActive");
                this.listGrandeza = listGrandeza.filter(grd => grd.comercializar == this.isUsuarioInterno == true ? grd.comercializar : 1);

                this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
                this.listFormaPagamento = await this.$store.dispatch("formaPagamentoModule/List");
                this.listTipoFrete = await this.$store.dispatch("tipoFreteModule/List");

                this.loadingDistribuidor = true;
                this.loadingClientes = true;

                this.listDistribuidor = await this.$store.dispatch("empresaModule/ListDistribuidor");
                this.loadingDistribuidor = false;

                this.listClientes = await this.$store.dispatch("empresaModule/ListClientes");
                this.loadingClientes = false;

                const configuracaoSistemaResult = await this.$store.dispatch("configuracaoSistemaModule/GetById", 1);
                this.configuracaoSistema = configuracaoSistemaResult.result;
            },

            async novaBusca() {
                this.codigoProduto = "";
                this.grandezaSelected = null;
                this.tipoProdutoSelected = null;
                this.listParametro = [];
                this.listProduto = [];
                this.listFamilias = [];
                this.listFamiliasAux = [];
                this.familiaProdutoSelected = null;
                this.precoCustoProdutoCalculado = 0;
            },

            async verificaKeyAccount() {

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined && this.tipoClienteSelected.id == 5) {

                    let listKeyAccount = await this.$store.dispatch("empresaModule/ListKeyAccount");

                    this.listClientesFiltered = [...listKeyAccount];
                }
                else {
                    this.listClientesFiltered = [...this.listClientes];
                }
            },

            async getListProdutosKeyAccount() {
                    
                let idTipoCliente = 0;

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                    idTipoCliente = this.tipoClienteSelected.id;
                }

                if (idTipoCliente == 5) {

                    if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                        this.listProdutoKeyAccount = await this.$store.dispatch("keyAccountModule/ListarProdutos", this.orcamentoRequest.clienteSelected.id);

                        await this.carregarDescontos(false);
                        
                        this.calcularTotal();
                    }
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("orcamentoModule/GetById", this.id);

                    if (response.success) {
                        this.orcamentoRequest = response.result;
                        this.orcamentoRequestClone = {...response.result};

                        this.marcaSelected = {
                            id: this.orcamentoRequest.idMarca,
                            descricao: this.orcamentoRequest.marcaDescricao
                        }

                        this.tipoClienteSelected = {
                            id: this.orcamentoRequest.tipoCliente,
                            descricao: this.orcamentoRequest.tipoClienteDescricao
                        }

                        if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                            let idCliente = this.orcamentoRequest.clienteSelected.id;
                            this.listProdutoKeyAccount = await this.$store.dispatch("keyAccountModule/ListarProdutos", idCliente);
                        }

                        if (this.orcamentoRequest.tipoPedido != null && this.orcamentoRequest.tipoPedido != undefined && this.orcamentoRequest.tipoPedido != '') {
                            let tipoPedido = this.listTipoPedido.filter(tpd => tpd.id == this.orcamentoRequest.tipoPedido)[0];

                            this.orcamentoRequest.tipoPedidoSelected = tipoPedido;
                        }

                        this.entregaTurbo = this.orcamentoRequest.entregaTurbo;
                        this.aplicarEntregaTurbo(true);

                        // await this.carregarDescontos(false);
                        // await this.verificaKeyAccount();
                        // await this.getListProdutosKeyAccount();
                    }

                    if (this.clonar == true) {

                        this.dialogMessage = {
                            show: true,
                            headerText: 'Atenção',
                            headerColor: 'orange',
                            headerFontColor: 'white',
                            bodyText: "Para cotações clonadas é recomendado que você revise as quantidades dos itens antes de salvar!",
                            methodOK: (() => { this.dialogMessage.show = false; }),
                        };
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/cotacao/cotacaoList" });
            },

            async confirmarExclusaoItemCotacao(itemCotacao) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: `Deseja realmente excluir o ${itemCotacao.descricaoProduto}?`,
                    methodConfirm: this.excluirItemCotacao,
                    params: itemCotacao.item
                };

            },

            async excluirItemCotacao(item) {

                let index = 0;

                const listItens = [...this.orcamentoRequest.listItemsOrcamento];

                listItens.forEach( itemCotacao => {
                    
                    if (itemCotacao.item == item) {
                        this.orcamentoRequest.listItemsOrcamento.splice(index, 1);

                        if (this.orcamentoRequest.listItemsOrcamento.length <= 0) {
                            this.valorTotalFormatado = "R$ 0,00";
                        }
                    }

                    index++
                })

            },            

            async atualizarQtde(operacao, item) {
                if (operacao == '+') {
                    item.qtde = parseInt(item.qtde) + 1;
                }
                else {
                    item.qtde = parseInt(item.qtde) - 1;
                }

                if (item.qtde == null || item.qtde == undefined || item.qtde == 0) {
                    item.qtde = 0
                }

                if (item.qtde == 0) {
                    this.confirmarExclusaoItemCotacao(item)
                }
                
                this.calcularTotal();

                this.validarCampanhaCredito(true);
            },            

            async calcularImpostos(item, custoTotal) {

                if (item.precoAplicadoManualmente != 1) {

                    if (this.tipoClienteSelected == null || this.tipoClienteSelected == undefined) {
                        this.showToast("error", "Aviso!", "É necessário selecionar um tipo de cliente");
                        return 0;
                    }
                    else {

                        let tipoCliente = 'D';
                        let codigo = '';
                        let loja = '';

                        if (this.isUsuarioInterno == true) {

                            if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                                tipoCliente = this.tipoClienteSelected.descricao.substring(0, 1);
                                codigo = this.orcamentoRequest.clienteSelected.id;
                                loja = this.orcamentoRequest.clienteSelected.branch;
                            }
                        }
                        else {
                            codigo = this.userLoggedGetters.distribuidorSelected.id;
                            loja = this.userLoggedGetters.distribuidorSelected.branch;
                        }

                        let impostoRequest = {
                            CODIGO: codigo,
                            LOJA: loja,
                            TIPOCLI: tipoCliente,
                            LISTITENS: [{
                                PROD: item.codigoProduto,
                                VALOR: custoTotal,
                                NCM: item.ncm,
                                ORIGEM: item.importado.toString()
                            }]
                        };

                        let resultImpostos = await this.$store.dispatch("produtoModule/ConsultarImpostos", impostoRequest);

                        if (resultImpostos && resultImpostos.totalValorMercadoria != 0 && resultImpostos.listItens.length > 0) {
                            custoTotal += resultImpostos.listItens[0].valICM + resultImpostos.listItens[0].valPIS + resultImpostos.listItens[0].valCOF;
                            item.aliqICMSFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqICM);
                            item.valICMSFormatted = formaterDecimalBR(resultImpostos.listItens[0].valICM);
                            item.aliqPISFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqPIS);
                            item.valPISFormatted = formaterDecimalBR(resultImpostos.listItens[0].valPIS);
                            item.aliqCOFINSFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqCOF);
                            item.valCOFINSFormatted = formaterDecimalBR(resultImpostos.listItens[0].valCOF);
                            item.aliqIPIFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqIPI);
                            item.valorIPIFormatted = formaterDecimalBR(resultImpostos.listItens[0].valIPI);
                            item.valorIPI = resultImpostos.listItens[0].valIPI;
                            item.qtdeEstoque = resultImpostos.listItens[0].qtdDisp;
                        }
                        else {
                            item.aliqICMSFormatted = formaterDecimalBR(0);
                            item.valICMSFormatted = formaterDecimalBR(0);
                            item.aliqPISFormatted = formaterDecimalBR(0);
                            item.valPISFormatted = formaterDecimalBR(0);
                            item.aliqCOFINSFormatted = formaterDecimalBR(0);
                            item.valCOFINSFormatted = formaterDecimalBR(0);
                            item.aliqIPIFormatted = formaterDecimalBR(0);
                            item.valorIPIFormatted = formaterDecimalBR(0);
                            item.valorIPI = 0;
                            item.qtdeEstoque = 0;
                        }

                        item.prazoEntregaDescricao = this.textoDias;
                        
                        let prazoEntregaConfigurado = [];

                        if (item.listPrazoEntrega != null &&
                            item.listPrazoEntrega != undefined) {
                            prazoEntregaConfigurado = item.listPrazoEntrega;
                        }

                        if (prazoEntregaConfigurado != null && 
                            prazoEntregaConfigurado != undefined &&
                            prazoEntregaConfigurado.length > 0) {

                            if (item.qtdeEstoque <= 0) {
                                const listPrazos = prazoEntregaConfigurado.filter(prz => item.qtde >= prz.qtde);
                                const qtdePrazoEntrega = listPrazos[listPrazos.length - 1].prazoEntrega;

                                if (qtdePrazoEntrega == 0) {
                                    item.prazoEntrega = this.consultarFabricaDescricao;
                                    item.prazoEntregaDescricao = '';
                                }
                                else {
                                    item.prazoEntrega = `${qtdePrazoEntrega}`;
                                }
                            }
                            else if (item.qtdeEstoque >= item.qtde) {
                                item.prazoEntrega = `3`
                            }
                            else {
                                const listPrazos = prazoEntregaConfigurado.filter(prz => item.qtde >= prz.qtde && prz.prazoEntregaDefinido == 1);
                                const qtdePrazoEntrega = listPrazos[listPrazos.length - 1].prazoEntrega;

                                item.prazoEntrega = `3`
                                item.prazoEntregaDescricao = `${this.textoDias} (${item.qtdeEstoque} peças) e ${qtdePrazoEntrega} dias úteis para as restantes`;
                                item.showDialogFaturamentoParcial = true;
                            }
                        }
                        else {
                            item.prazoEntrega = this.consultarFabricaDescricao;
                            item.prazoEntregaDescricao = '';
                        }
                        item.prazoEntregaOriginal = item.prazoEntrega;
                    }
                }
                
                return custoTotal;
            },

            async calcularTotal(showLoading = true, calculaQtde = true) {

                if (showLoading == true) {
                    this.showLoading();
                }
                
                var valorTotal = 0;
                this.valorTotalIPI = 0;
                this.impostoCalculado = true;
                const percentualEntregaTurbo = 0.5;
                
                await this.orcamentoRequest.listItemsOrcamento.forEach(async item => {

                    let novoPreco = parseFloat(formaterDecimalBRServer(item.precoSemImpostosFormatted));

                    if (item.qtde == null || item.qtde == undefined || item.qtde == 0) {
                        item.qtde = 1
                    }

                    if (item.precoAplicadoManualmente == 1 && item.novoPreco != null && item.novoPreco != undefined) {
                        novoPreco = parseFloat(formaterDecimalBRServer(item.novoPreco));
                    }
                    else {
                        this.impostoCalculado = false;
                    }
                    
                    let calculaDescontos = item.precoAplicadoManualmente != 1;

                    let descontoUsuario = formaterDecimalBRServer(item.descontoUsuarioFormatted);
                    descontoUsuario = 1 - (descontoUsuario / 100);

                    let moedaProduto = "R$";

                    let descontoPermitido = await this.descontoUsuarioPermitido(descontoUsuario, false);

                    if (descontoPermitido) {

                        let custoProduto = parseFloat(item.precoCusto);

                        if (this.entregaTurbo == 1) {
                            //pedidos com entrega turbo, aumenta o preço em 50%
                            const valorEntregaTurbo = parseFloat(item.precoCusto) * percentualEntregaTurbo;
                            custoProduto += valorEntregaTurbo;
                        }

                        let produtoKeyAccountAplicado = false;

                        if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {

                            let produtoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto == item.codigoProduto);

                            if (produtoKeyAccount != null && produtoKeyAccount != undefined && produtoKeyAccount.length > 0) {
                                custoProduto = produtoKeyAccount[0].preco;
                                moedaProduto = produtoKeyAccount[0].cambioSimbolo;
                                produtoKeyAccountAplicado = true;
                                item.keyAccount = 1;
                            }
                        }

                        const listDesconto = this.listDescontos.filter(des => des.idProduto == item.idProduto && des.qtdePecasLimite <= item.qtde && produtoKeyAccountAplicado == false);

                        item.descontoPorQtdeAplicado = false;

                        let desconto = 1;
                        let descontoBase = 1;
                        let descontoCampanhaEstoque = 1;
                        let descontoCampanhaCredito = 1;
                        let descontoCampanhaOuroPrata = 1;
                        let descontoDistribuidor = 1;
                        let listCampanhaOuroPrata = [];

                        if (calculaDescontos) {

                            if (listDesconto != null && listDesconto != undefined && listDesconto.length > 0) {

                                item.descontoPorQtdeAplicado = true;
                                let descricaoDesconto = "";
                                let descricaoDescontoBase = "";
                                let descontoAplicado = false;
                                let descontoBaseAplicado = false;

                                listDesconto.forEach(itemDesconto => {

                                    if (itemDesconto.desconto > 0 && descontoAplicado == false) {
                                        descricaoDesconto = `| ${formaterDecimalBR(parseFloat(itemDesconto.desconto))} % (Desconto por Qtde)`;
                                        desconto = 1 - (parseFloat(itemDesconto.desconto) / 100);
                                        descontoAplicado = true;
                                    }

                                    if (itemDesconto.descontoBase > 0 && descontoBaseAplicado == false) {
                                        descricaoDescontoBase = `${formaterDecimalBR(parseFloat(itemDesconto.descontoBase))} % (Desconto Base)`;
                                        descontoBase = 1 - (parseFloat(itemDesconto.descontoBase) / 100);
                                        descontoBaseAplicado = true;
                                    }
                                });
                                
                                item.descontoPorQtdePercentual = `${descricaoDescontoBase} ${descricaoDesconto}`
                            }
                            
                            //DESCONTO CAMPANHA DE ESTOQUE
                            if (this.listCampanhaEstoque.length > 0) {
                                item.descontoCampanhaEstoque = this.listCampanhaEstoque[0].desconto

                                descontoCampanhaEstoque = 1 - (item.descontoCampanhaEstoque / 100);
                            }

                            //DESCONTO CAMPANHA DE CRÈDITO
                            if (this.mostrarCampanhaCredito) {
                                item.descontoCampanhaCredito = this.listCampanhaCredito[0].desconto

                                descontoCampanhaCredito = 1 - (item.descontoCampanhaCredito / 100);
                            }
                            
                            //DESCONTO CAMPANHA OURO E PRATA
                            listCampanhaOuroPrata = await this.consultarCampanhaOuroPrata(item.idMarca)
                            if (listCampanhaOuroPrata.length > 0) {

                                let itemCampanha = listCampanhaOuroPrata[0];

                                if (itemCampanha != null && itemCampanha != undefined) {

                                    item.descontoCampanhaOuroPrata = itemCampanha.desconto
                                    item.descontoCampanhaOuroPrataDescricao = itemCampanha.descricao

                                    descontoCampanhaOuroPrata = 1 - (item.descontoCampanhaOuroPrata / 100);
                                }
                            }

                            //DESCONTO DO DISTRIBUIDOR
                            if (this.distribuidorConfig != null && this.distribuidorConfig != undefined && this.distribuidorConfig.listDesconto != null && this.distribuidorConfig.listDesconto != undefined && this.distribuidorConfig.listDesconto.length > 0) {

                                let desconto = this.descontoDistribuidor;
                                descontoDistribuidor = 1 - (formaterDecimalBRServer(desconto) / 100);
                            }
                        }

                        let precoSemImpostos = Math.floor(
                                ( 
                                    (
                                        (
                                            (
                                                (
                                                    ( 
                                                        ( (custoProduto * descontoBase) * desconto ) * descontoUsuario
                                                    ) 
                                                    * descontoCampanhaEstoque
                                                ) 
                                                * descontoCampanhaOuroPrata
                                            ) 
                                            * descontoCampanhaCredito
                                        ) 
                                        * descontoDistribuidor
                                    )
                                ) * this.og) / this.og;

                        item.valorCertificacaoEX = 0;

                        if (item.certificacaoEX == 1) {
                            const listFamiliaEX = [...this.orcamentoRequest.listItemsOrcamento.filter(car => car.familiaEX == item.familiaEX)];
                            let qtdeFamiliaEX = 0;
                            listFamiliaEX.forEach(itemFamiliaEX => {
                                qtdeFamiliaEX += parseInt(itemFamiliaEX.qtde);
                            })

                            item.valorCertificacaoEX = Math.floor(((this.configuracaoSistema.valorCertificacaoEX / qtdeFamiliaEX) * this.og)) / this.og;
                            item.valorCertificacaoEXFormatted = `${moedaProduto} ${formaterDecimalBR(item.valorCertificacaoEX)}`;

                            if (qtdeFamiliaEX > this.configuracaoSistema.qtdeMaximaCertificacaoEX) {
                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Atenção',
                                    headerColor: 'orange',
                                    headerFontColor: 'white',
                                    bodyText: `A família de produto ${item.familiaEX} possui Certificação EX. <br />
                                                Não é permitido solicitar qtde acima de ${this.configuracaoSistema.qtdeMaximaCertificacaoEX} unidades para o conjunto de produtos da mesma família.`,
                                    methodOK: ( () => { 

                                        listFamiliaEX.forEach(itemFamiliaEX => {
                                            let itemCarrinho = this.orcamentoRequest.listItemsOrcamento.filter(car => car.codigoProduto == itemFamiliaEX.codigoProduto)[0];

                                            if (itemCarrinho != null && itemCarrinho != undefined) {
                                                itemCarrinho.qtde = 1;
                                            }
                                        });

                                        this.calcularTotal(showLoading, calculaQtde);
                                    }),
                                };
                                
                                return;
                            }
                        }

                        let precoCustoTotal = 0;

                        if (item.precoAplicadoManualmente == 1) {
                            precoSemImpostos = novoPreco;
                            let qtde = item.qtde

                            if (calculaQtde == false) {
                                qtde = 1
                            }
                            precoCustoTotal += Math.floor((( precoSemImpostos * qtde) * this.og)) / this.og;
                        }
                        else {
                            item.precoSemImpostosOriginal = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                            precoCustoTotal += Math.floor((( precoSemImpostos * item.qtde) * this.og)) / this.og;
                        }

                        item.descontoAplicado = descontoBase != 1 || desconto != 1 || descontoUsuario != 1 || this.listCampanhaEstoque.length > 0 || listCampanhaOuroPrata.length > 0 || this.listCampanhaCredito.length > 0 || descontoDistribuidor != 1;
                        
                        item.precoSemImpostos = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                        item.precoUnitarioComImpostos = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                        item.precoCustoTotal = `${moedaProduto} ${formaterDecimalBR(precoCustoTotal)}`;
                        item.margemEfetiva = await this.calcularMargemEfetiva(item);
                        item.moedaProduto = moedaProduto;
                        item.descontoUsuario = (1 - parseFloat(descontoUsuario) ) * 100;
                        item.descontoQtde = (1 - parseFloat(desconto) ) * 100;
                        item.descontoQtdeBase = (1 - parseFloat(descontoBase) ) * 100;

                        valorTotal += precoCustoTotal;
                    }
                    else {
                        item.desconto = "0,00"
                    }

                    this.valorTotalSemDescontoCampanha = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;
                    this.valorTotalFormatado = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;

                    let custoTotalProdutoComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos)) * item.qtde;

                    if (calculaQtde == false) {
                        custoTotalProdutoComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos));

                        //Ajustar os Impostos
                        this.ajustarImpostos(item);
                    }

                    item.precoUnitarioTotalComImpostos = `${moedaProduto} ${formaterDecimalBR(Math.floor(custoTotalProdutoComImpostos * this.og) / this.og)}`;

                    ///CALCULO DO VALOR UNITÁRIO
                    let valorTotalItem = this.mostrarBotaoImposto ? parseFloat(formaterDecimalBRServer(item.precoCustoTotal)) : parseFloat(formaterDecimalBRServer(item.precoUnitarioTotalComImpostos));

                    if (item.precoAplicadoManualmente == 1) {

                        valorTotalItem = parseFloat(formaterDecimalBRServer(item.precoCustoTotal));

                        item.precoUnitarioTotalComImpostos = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotalItem * this.og) / this.og)}`;
                    }

                    const valorUnitario = Math.floor(((valorTotalItem / item.qtde) * this.og)) / this.og;
                    item.valorUnitario = `${moedaProduto} ${formaterDecimalBR(valorUnitario)}`;

                    if (item.precoAplicadoManualmente == 1) {
                        item.precoCusto = item.valorUnitario;
                        item.precoUnitarioComImpostos = item.valorUnitario;

                        //Ajustar os Impostos
                        this.ajustarImpostos(item);
                    }
                    
                    if (showLoading == true) {
                        this.hideLoading();
                    }
                });

                if (this.orcamentoRequest.listItemsOrcamento.length <= 0 && showLoading == true) {
                    this.hideLoading();
                }
            },

            async ajustarImpostos(item) {
                const precoUnitarioComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos));

                const aliqPIS = parseFloat(formaterDecimalBRServer(item.aliqPISFormatted)) / 100;
                const valPIS = (aliqPIS) * precoUnitarioComImpostos;

                const aliqCOFINS = parseFloat(formaterDecimalBRServer(item.aliqCOFINSFormatted)) / 100;
                const valCOFINS = (aliqCOFINS) * precoUnitarioComImpostos;

                const aliqICMS = parseFloat(formaterDecimalBRServer(item.aliqICMSFormatted)) / 100;
                const valICMS = (aliqICMS) * precoUnitarioComImpostos;

                const precoUnitarioComImpostosCalculado = precoUnitarioComImpostos * (1 - aliqPIS - aliqCOFINS) * (1 - aliqICMS);
                item.valPISFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valPIS * this.og) / this.og)}`;
                item.valCOFINSFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valCOFINS * this.og) / this.og)}`;
                item.valICMSFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valICMS * this.og) / this.og)}`;
                item.precoSemImpostosOriginal = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(precoUnitarioComImpostosCalculado * this.og) / this.og)}`;
            },

            async carregarDescontos(limparClienteDistribuidor = true) {
                
                this.listDescontos = [];

                if (limparClienteDistribuidor == true) {
                    this.orcamentoRequest.clienteSelected = undefined;
                    this.orcamentoRequest.distribuidorSelected = undefined;
                }

                let tipoCliente = 2;

                if (this.isUsuarioInterno == true) {
                    
                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        tipoCliente = this.tipoClienteSelected.id;

                        this.orcamentoRequest.listItemsOrcamento.forEach(item => {

                            if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {
                                let existeProdutoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto.toString().trim() == item.codigoProduto.toString().trim());

                                if (existeProdutoKeyAccount == null || existeProdutoKeyAccount == undefined || existeProdutoKeyAccount.length <= 0) {
                                    if (this.listProdutoKeyAccount[0].tipoCliente != null && this.listProdutoKeyAccount[0].tipoCliente != undefined && this.listProdutoKeyAccount[0].tipoCliente != 0) {
                                        tipoCliente = this.listProdutoKeyAccount[0].tipoCliente;
                                    }
                                }
                            }
                        })
                    }
                }

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {

                    this.showLoading();

                    let listIDsProduto = this.orcamentoRequest.listItemsOrcamento.map(car => car.idProduto)

                    let descontoFilter = {
                        tipoCliente,
                        listIDsProduto: listIDsProduto
                    }

                    this.listDescontos = await this.$store.dispatch("descontoModule/ListTipoClienteProduto", descontoFilter);

                    this.hideLoading();
                }

                this.consultarCampanhas();
            },

            async descontoUsuarioPermitido(valorDesconto, validaDescontoZerado = true) {

                if (valorDesconto <= 0 && validaDescontoZerado) {
                    
                    this.showToast("error", "Aviso!", `Desconto não informado!`);
                    return false;
                }
                // else if (valorDesconto > 1 && valorDesconto > parseFloat(this.userLoggedGetters.perfil.desconto)) {
                    
                //     this.showToast("error", "Aviso!", `Desconto maior que o permitido (${this.userLoggedGetters.perfil.descontoFormatted}%)`);
                //     return false;
                // }
                else {
                    return true
                }
            },

            async confirmarDescontoUsuario() {

                let descontoUsuario = formaterDecimalBRServer(this.descontoUsuarioFormatado)

                if (await this.descontoUsuarioPermitido(descontoUsuario)) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: `Você irá aplicar ${this.descontoUsuarioFormatado}% de desconto a todos os produtos do carrinho, confirma sua decisão?`,
                        methodConfirm: this.aplicarDescontoUsuario,
                        params: 0
                    };
                }
            },

            getDescontoDistribuidor() {

                let desconto = "0,00";

                if (this.distribuidorConfig != null && this.distribuidorConfig != undefined &&
                    this.distribuidorConfig.listDesconto != null && this.distribuidorConfig.listDesconto != undefined && this.distribuidorConfig.listDesconto.length > 0) {

                    let descontoDist = this.distribuidorConfig.listDesconto.filter(des => des.marcaSelected.id == this.marcaSelected.id)[0];

                    if (descontoDist != null && descontoDist != undefined) {
                        desconto = descontoDist.descontoFormatted;
                    }
                }

                this.descontoDistribuidor = desconto;
            },

            async aplicarDescontoUsuario() {

                this.orcamentoRequest.listItemsOrcamento.forEach(async item => {
                    item.desconto = this.descontoUsuarioFormatado
                })

                await this.calcularTotal();
            },

            getMargemEfetiva(itemProduto) {

                const precoCustoSemMargem = parseFloat(itemProduto.valorCustoProduto) * itemProduto.qtde;
                const precoSemImpostos = parseFloat(formaterDecimalBRServer(itemProduto.precoSemImpostos)) * itemProduto.qtde;

                return Math.round(Math.floor((((precoSemImpostos - precoCustoSemMargem) / precoSemImpostos) * 100) * this.og) / this.og);
            },

            async checkMargemMinima(itemProduto) {

                let isValid = true;

                const margemEfetiva = this.getMargemEfetiva(itemProduto);

                const margemMinima = itemProduto.importado == 1 ? parseFloat(this.userLoggedGetters.perfil.margemMinimaProdutoImportado) : parseFloat(this.userLoggedGetters.perfil.margemMinimaProdutoNacional);

                if (this.isUsuarioInterno == true &&
                    this.isTipoKeyAccount == false &&
                    margemEfetiva < margemMinima && 
                    (
                        itemProduto.mensagemMargemMinimaApresentada == null || 
                        itemProduto.mensagemMargemMinimaApresentada == undefined || 
                        itemProduto.mensagemMargemMinimaApresentada == false
                    )
                   ) {

                    isValid = false;

                    this.dialogMessage = {
                        show: true,
                        headerText: 'Atenção',
                        headerColor: 'orange',
                        headerFontColor: 'white',
                        bodyText: `A margem efetiva de <strong>[${margemEfetiva}% do Produto: ${itemProduto.codigoProduto}]</strong> para o desconto aplicado é menor que o permitido <strong>[${margemMinima}%]</strong> para o seu perfil.
                                    <br /> 
                                    Favor entrar em contato com seu supervisor direto!`,
                        methodOK: (async () => { 
                            itemProduto.descontoUsuarioFormatted = "0,00";
                            itemProduto.mensagemMargemMinimaApresentada = true;
                            await this.calcularTotal(false);
                        }),
                    };
                }

                itemProduto.mensagemMargemMinimaApresentada = false;

                return isValid;
            },

            calcularMargemEfetiva(itemProduto) {

                const margemEfetiva = this.getMargemEfetiva(itemProduto);

                this.checkMargemMinima(itemProduto);

                return `${formaterDecimalBR(margemEfetiva)} %` ;
            },

            async consultarCampanhaOuroPrata(idMarca) {
                
                let idDistribuidor = "";
                let estado = "";

                if (this.isUsuarioInterno == true) {
                    
                    if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                        idDistribuidor = this.orcamentoRequest.clienteSelected.id;
                        estado = this.orcamentoRequest.clienteSelected.state;
                    }
                }
                else {
                    idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;
                    estado = this.userLoggedGetters.distribuidorSelected.state;
                }

                let campanhaOuroPrataParticipacaoFilter = {
                    idDistribuidor,
                    idMarca,
                    estado
                }

                let campanhaJahConsultada = this.listCampanhaOuroPrataConsultada.filter (cam => cam.idMarca == idMarca && cam.idDistribuidor == idDistribuidor)[0];

                this.listCampanhaOuroPrata = await this.$store.dispatch("campanhaOuroPrataModule/ConsultarParticipacao", campanhaOuroPrataParticipacaoFilter);

                if (campanhaJahConsultada == null || campanhaJahConsultada == undefined || campanhaJahConsultada.length <= 0) {
                    this.listCampanhaOuroPrata = await this.$store.dispatch("campanhaOuroPrataModule/ConsultarParticipacao", campanhaOuroPrataParticipacaoFilter);
                    this.listCampanhaOuroPrataConsultada.push({
                        idMarca,
                        idDistribuidor,
                        listCampanhaOuroPrata: this.listCampanhaOuroPrata
                    })
                }
                else {
                    this.listCampanhaOuroPrata = campanhaJahConsultada.listCampanhaOuroPrata;
                }

                return this.listCampanhaOuroPrata;
            },

            async validarCampanhaCredito(showLoading, calcularTotais = true) {

                if (this.mostrarBotaoConsultaSaldoCampanha == true) {

                    if (showLoading == true) {
                        this.showLoading();
                    }

                    let idDistribuidor = "";
                    this.listCampanhaCredito = [];

                    if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                        idDistribuidor = this.orcamentoRequest.clienteSelected.id;
                    }

                    let resultSaldoTotalCampanhaCredito = await this.$store.dispatch("campanhaCreditoModule/ConsultarSaldoCampanhaTotvs", idDistribuidor);
                    const valorSaldoCampanha = resultSaldoTotalCampanhaCredito.result;
                    let totalCotacao =  parseFloat(formaterDecimalBRServer(this.valorTotalFormatado)) + parseFloat(this.valorTotalIPI);

                    let possuiSaldoCampanha = valorSaldoCampanha > 0 && 
                                                valorSaldoCampanha > totalCotacao;

                    this.elegivelParaCampanha = possuiSaldoCampanha &&
                                                this.abaterSaldoCampanhaCredito == true;

                    this.disableSaldoCampanhaCredito = possuiSaldoCampanha == false;

                    if (valorSaldoCampanha <= 0 || this.elegivelParaCampanha == false) {
                        this.abaterSaldoCampanhaCredito = false;
                    }
                    else {
                        this.abaterSaldoCampanhaCredito = this.disableSaldoCampanhaCredito == false;
                    }

                    if (this.elegivelParaCampanha == true) {
                        
                        let campanhaCreditoParticipacaoFilter = {
                            idDistribuidor
                        }
                        
                        this.listCampanhaCredito = await this.$store.dispatch("campanhaCreditoModule/ConsultarParticipacao", campanhaCreditoParticipacaoFilter);
                    }

                    this.setCondicaoFormaPgto();

                    if (showLoading == true) {

                        if (calcularTotais == true) {
                            await this.calcularTotal();
                        }

                        this.hideLoading();
                    }
                }
            },
            
            async consultarCampanhas() {

                let idDistribuidor = "";
                let idCondicaoPagamento = 0;
                let estado = "";
                let valor = parseFloat(formaterDecimalBRServer(this.valorTotalSemDescontoCampanha));
                this.distribuidorConfig = null;
                this.listCampanhaCredito = [];
                this.listCondicaoPagamento = [];
                this.abaterSaldoCampanhaCredito = true;

                if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                    idDistribuidor = this.orcamentoRequest.clienteSelected.id;
                    estado = this.orcamentoRequest.clienteSelected.state;
                }

                if (this.condicaoPagamentoSelected != null && this.condicaoPagamentoSelected != undefined) {
                    idCondicaoPagamento = this.condicaoPagamentoSelected.id;
                }

                let campanhaEstoqueParticipacaoFilter = {
                    idDistribuidor,
                    idCondicaoPagamento,
                    estado,
                    valor
                }

                this.listCampanhaEstoque = await this.$store.dispatch("campanhaEstoqueModule/ConsultarParticipacao", campanhaEstoqueParticipacaoFilter);

                if (idDistribuidor != "") {

                    let resultDistribuidor = await this.$store.dispatch("distribuidorConfigModule/GetByDistribuidor", idDistribuidor);

                    this.distribuidorConfig = resultDistribuidor.result;

                    await this.validarCampanhaCredito(false);

                    if (this.distribuidorConfig.listaCondicaoPagamento != null && this.distribuidorConfig.listaCondicaoPagamento != undefined && this.distribuidorConfig.listaCondicaoPagamento != '') {
                        try {
                            this.listCondicaoPagamento = JSON.parse(this.distribuidorConfig.listaCondicaoPagamento);
                        }
                        catch {
                            this.listCondicaoPagamento = [];
                        }
                    }

                    await this.getDescontoDistribuidor();
                }
                
                if (this.isUsuarioInterno == true) {
                    this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
                }

                if (this.listCondicaoPagamento == null || this.listCondicaoPagamento == undefined || this.listCondicaoPagamento.length <= 0 ) {
                    this.listCondicaoPagamento.push ({
                        id: 1,
                        descricao: "À VISTA"
                    })
                }

                this.setCondicaoFormaPgto();

                await this.calcularTotal();
            },       

            async confirmarCalculoImpostos() {

                let mostrarConfirmacao = true;
                
                if (this.isUsuarioInterno == true) {

                    if (this.tipoClienteSelected == null || this.tipoClienteSelected == undefined || this.tipoClienteSelected.id == 0) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um tipo de cliente");
                        mostrarConfirmacao = false;
                    }
                    else if (this.tipoClienteSelected.id == 2 && (this.orcamentoRequest.clienteSelected == null || this.orcamentoRequest.clienteSelected == undefined)) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um distribuidor");
                        mostrarConfirmacao = false;
                    }
                    else if (this.tipoClienteSelected.id != 2 && (this.orcamentoRequest.clienteSelected == null || this.orcamentoRequest.clienteSelected == undefined)) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um cliente");
                        mostrarConfirmacao = false;
                    }
                }

                if (mostrarConfirmacao) {

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: `Você irá calcular os impostos, confirma sua decisão?`,
                        methodConfirm: this.gerarImpostos,
                        params: 0
                    };
                }
            },

            async gerarImpostos() {
                

                this.showLoading();

                await this.calcularTotal(false);

                var valorTotal = 0;

                let qtdeTotalProdutos = 1;
                
                this.valorTotalIPI = 0;
                
                await this.orcamentoRequest.listItemsOrcamento.forEach(async item => {

                    const custoTotal = parseFloat(formaterDecimalBRServer(item.precoCustoTotal));
                    const custoProduto = parseFloat(formaterDecimalBRServer(item.precoSemImpostos));
                    
                    const custoTotalComImpostos = await this.calcularImpostos(item, custoTotal);
                    const custoProdutoComImpostos = await this.calcularImpostos(item, custoProduto);

                    const custoTotalProdutoComImpostos = parseFloat(custoProdutoComImpostos) * item.qtde;

                    item.precoCustoTotal = `${item.moedaProduto} ${formaterDecimalBR(custoTotal)}`;
                    item.precoUnitarioComImpostos = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(custoProdutoComImpostos * this.og) / this.og)}`;
                    item.precoUnitarioTotalComImpostos = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(custoTotalProdutoComImpostos * this.og) / this.og)}`;

                    valorTotal += custoTotalComImpostos + (item.valorCertificacaoEX * item.qtde);

                    this.valorTotalSemDescontoCampanha = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;
                    this.valorTotalFormatado = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;
                    this.valorTotalIPI += item.valorIPI;

                    if (qtdeTotalProdutos == this.orcamentoRequest.listItemsOrcamento.length) {
                        this.hideLoading();
                        this.impostoCalculado = true;
                        await this.validarCampanhaCredito(true, false);
                    }

                    qtdeTotalProdutos++;
                });
            },

            async validarCotacao() {

                let mostrarMensagemConfirmacao = true;                
                    
                let msgDataEntregaInvalida = '';
                let msgProdutoAgrupado = '';
                const dataAtual = moment();
                const anoAtual = moment(new Date()).format('YYYY');
                let index = 1;
                await this.orcamentoRequest.listItemsOrcamento.forEach(async item => {

                    const margemMinimaIsValid = await this.checkMargemMinima(item);

                    if (margemMinimaIsValid == false) {
                        mostrarMensagemConfirmacao = false;
                    }

                    if (item.entregaProgramada == 1) {

                        const dataEntregaValida = moment(formatDateToServer(item.dataEntregaFormatted));

                        if (!dataEntregaValida.isValid()) {
                            msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} inválida <br />`;
                        }

                        if (dataAtual.isAfter(dataEntregaValida)) {
                            msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} não pode ser menor que hoje <br />`;
                        }

                        if (this.userLoggedGetters.perfil.dataEntregaAnoSuperior != 1) {
                            const anoDataEntrega = dataEntregaValida.format('YYYY');

                            if (parseInt(anoDataEntrega) > anoAtual) {
                                msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} deve ser do ano atual <br />`;
                            }
                        }
                    }
                        
                    if (item.produtoAgrupado == 1 && (item.tagAgrupamento == null || item.tagAgrupamento == undefined || item.tagAgrupamento.toString().trim() == '') ) {
                        msgProdutoAgrupado += `Tag de agrupamento do produto ${item.codigoProduto} não pode ser vazia <br />`;
                    }
                        
                    if (this.orcamentoRequest.listItemsOrcamento.length == index) {
                        if (msgDataEntregaInvalida != null && msgDataEntregaInvalida != undefined && msgDataEntregaInvalida != '') {
                            mostrarMensagemConfirmacao = false;

                            this.showToast("error", "Aviso!", `Há inconsistências no(s) produto(s) abaixo referente a data de entrega: <br /><br /> ${msgDataEntregaInvalida}`);
                        }

                        if (msgProdutoAgrupado != null && msgProdutoAgrupado != undefined && msgProdutoAgrupado != '') {
                            mostrarMensagemConfirmacao = false;

                            this.showToast("error", "Aviso!", `Há inconsistências no(s) produto(s) abaixo referente ao agrupamento : <br /><br /> ${msgProdutoAgrupado}`);
                        }
                    }

                    index++;
                })

                setTimeout(() => {
                    if (mostrarMensagemConfirmacao == true) {
                        this.confirmacaoGravacaoCotacao();
                    }
                }, 500);
            },

            async confirmacaoGravacaoCotacao() {
                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: `Confirma as alterações feitas na cotação?`,
                    methodAcao1: ( () => {} ),
                    methodConfirm: this.save,
                    params: 0
                };
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    if (this.impostoCalculado == false) {
                        await this.gerarImpostos();
                    }

                    this.orcamentoRequest.id = this.id;
                    
                    if (this.clonar == true) {
                        this.orcamentoRequest.id = 0;
                        this.orcamentoRequest.status = 1;
                    }

                    if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                        this.orcamentoRequest.idCliente = this.orcamentoRequest.clienteSelected.id;
                    }

                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        this.orcamentoRequest.idTipoCliente = this.tipoClienteSelected.id;
                    }

                    if (this.orcamentoRequest.condicaoPagamentoSelected != null && this.orcamentoRequest.condicaoPagamentoSelected != undefined) {
                        this.orcamentoRequest.idCondicaoPagamento = this.orcamentoRequest.condicaoPagamentoSelected.id;
                    }

                    if (this.orcamentoRequest.formaPagamentoSelected != null && this.orcamentoRequest.formaPagamentoSelected != undefined) {
                        this.orcamentoRequest.idFormaPagamento = this.orcamentoRequest.formaPagamentoSelected.id;
                    }

                    if (this.orcamentoRequest.tipoFreteSelected != null && this.orcamentoRequest.tipoFreteSelected != undefined) {
                        this.orcamentoRequest.idTipoFrete = this.orcamentoRequest.tipoFreteSelected.id;
                    }

                    if (this.orcamentoRequest.tipoPedidoSelected != null && this.orcamentoRequest.tipoPedidoSelected != undefined) {
                        this.orcamentoRequest.tipoPedido = this.orcamentoRequest.tipoPedidoSelected.id;
                    }

                    this.orcamentoRequest.abaterSaldoCampanhaCredito = this.elegivelParaCampanha == true && this.abaterSaldoCampanhaCredito == true ? 1 : 0;
                    this.orcamentoRequest.entregaTurbo = this.entregaTurbo == 1 || this.entregaTurbo == true ? 1 : 0;
                    
                    let listItemsOrcamento = [];

                    this.orcamentoRequest.listItemsOrcamento.forEach(item => {

                        let prazoEntrega = 0;
                        let id = item.id;

                        if (item.prazoEntrega != this.consultarFabricaDescricao) {
                            prazoEntrega = item.prazoEntrega;
                        }

                        if (this.orcamentoRequest.id == 0) {
                            id = 0;
                        }

                        let entregaProgramada = 0;
                        let dataEntrega = null;

                        if (item.entregaProgramada != null && item.entregaProgramada != undefined) {
                            entregaProgramada = item.entregaProgramada;

                            if (entregaProgramada == 1) {
                                dataEntrega = formatDateToServer(item.dataEntregaFormatted);
                            }
                        }
                        const icpProduto = this.valorTotalParametrosSelecionados(item, true);

                        let precoSemImpostos = item.precoSemImpostos;

                        if (item.precoAplicadoManualmente == 1 && item.novoPreco != null && item.novoPreco != undefined) {
                            precoSemImpostos = parseFloat(formaterDecimalBRServer(item.novoPreco));
                        }
                        else if (item.id == 0) {
                            precoSemImpostos = item.precoCustoFormatted;
                        }
                        
                        listItemsOrcamento.push({
                            id,
                            idProduto: item.idProduto,
                            imagem: item.imagem,
                            codigoProduto: item.codigoProduto,
                            descricaoProduto: item.descricaoProduto,
                            listaIDParametroItem: item.listaIDParametroItem,
                            qtde: item.qtde,
                            prazoEntrega: prazoEntrega,
                            ncm: item.ncm,
                            valorCustoProduto: item.valorCustoProduto == 0 ? item.precoCustoProdutoSemMargem : item.valorCustoProduto,
                            precoSemImpostos,
                            descontoUsuario: item.descontoUsuario,
                            descontoCampanhaEstoque: item.descontoCampanhaEstoque,
                            descontoCampanhaCredito:  this.mostrarCampanhaCredito == true ? item.descontoCampanhaCredito : 0,
                            descontoCampanhaOuroPrata: item.descontoCampanhaOuroPrata,
                            descontoQtde: item.descontoQtde,
                            descontoQtdeBase: item.descontoQtdeBase,
                            aliqPIS: item.aliqPISFormatted,
                            aliqCOFINS: item.aliqCOFINSFormatted,
                            aliqICMS: item.aliqICMSFormatted,
                            aliqIPI: item.aliqIPIFormatted,
                            precoBruto: item.precoUnitarioComImpostos,
                            valorTotal: item.precoCustoTotal,
                            keyAccount: item.keyAccount,
                            precoAplicadoManualmente: item.precoAplicadoManualmente,
                            entregaProgramada: entregaProgramada,
                            dataEntrega: dataEntrega,
                            idCambioICP: icpProduto.idCambioICP == null || icpProduto.idCambioICP == undefined ? 0 : icpProduto.idCambioICP,
                            valorTotalICP: icpProduto.valorTotalICP == null || icpProduto.valorTotalICP == undefined ? 0 : icpProduto.valorTotalICP,
                            certificacaoEX: item.certificacaoEX,
                            valorCertificacaoEX: item.valorCertificacaoEX,
                            produtoAgrupado: item.produtoAgrupado,
                            tagAgrupamento: item.tagAgrupamento,
                            observacao: item.observacao
                        })
                    });

                    this.orcamentoRequest.listItemsOrcamento = listItemsOrcamento;

                    const result = await this.$store.dispatch("orcamentoModule/CreateUpdate", this.orcamentoRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },

            showDialogPrazoEntrega(itemCotacao) {
                this.dialogPrazoEntrega = {
                    show: true,
                    prazoEntrega: itemCotacao.prazoEntrega,
                    prazoEntregaOriginal: itemCotacao.prazoEntregaOriginal,
                    methodConfirm: (novoPrazoEntrega) => {
                        itemCotacao.prazoEntrega = novoPrazoEntrega;
                    }
                }
            },

            showDialogValorItem(itemCotacao) {

                this.dialogValorItem = {
                    show: true,
                    id: Math.random(),
                    codigoProduto: itemCotacao.codigoProduto,
                    precoLista: "0",
                    valor: itemCotacao.precoUnitarioTotalComImpostos,
                    methodConfirm: (novoValorItem) => {

                        const custoOriginal = parseFloat(formaterDecimalBRServer(itemCotacao.precoCustoTotal));
                        let desconto = ( (custoOriginal - parseFloat(formaterDecimalBRServer(novoValorItem))) / custoOriginal) * 100;

                        desconto = Math.floor(desconto * this.og) / this.og;

                        const novoPreco = `${itemCotacao.moedaProduto} ${novoValorItem}`;

                        itemCotacao.precoAplicadoManualmente = 1;
                        itemCotacao.precoCusto = novoPreco;
                        itemCotacao.novoPreco = novoPreco;
                        itemCotacao.desconto = formaterDecimalBR(desconto);

                        this.calcularTotal(true, false);
                    }
                }
            },

            abrirDialogSaldoCampanha() {
                
                let idDistribuidor = "0";

                if (this.orcamentoRequest.clienteSelected != null && this.orcamentoRequest.clienteSelected != undefined) {
                    idDistribuidor = this.orcamentoRequest.clienteSelected.id;
                }

                this.dialogSaldoCampanha = {
                    show: true,
                    idAux: Math.random(),
                    idDistribuidor
                }
            },

            async clonarItem(itemCotacao) {
                
                let itemCotacaoClone = {...itemCotacao};
                itemCotacaoClone.id = 0;
                itemCotacaoClone.itemClonado = true;
                itemCotacaoClone.precoSemImpostosOriginal = itemCotacaoClone.precoSemImpostos;
                this.orcamentoRequest.listItemsOrcamento.push(itemCotacaoClone);

                this.showToast("success", "Sucesso!", "Item clonado com sucesso!");
                await this.calcularTotal(true);
                this.validarCampanhaCredito(true);
            },

            async formatarData(itemProduto) {
                itemProduto.dataEntregaFormatted = formatarDataPadraoPtBR(itemProduto.dataEntrega);
                itemProduto.mnuDataEntrega = false;
            },

            async setCondicaoFormaPgto() {

                if (this.abaterSaldoCampanhaCredito == true && this.isTipoDistribuidor == true) {
                    const condicaoPagamentoAVista = this.listCondicaoPagamento.filter (cond => cond.descricao.toString().includes("VISTA"))[0];

                    this.orcamentoRequest.condicaoPagamentoSelected = condicaoPagamentoAVista;

                    const formaPagamentoDeposito = this.listFormaPagamento.filter (form => form.descricao.toString().includes("DEP"))[0];
                    this.orcamentoRequest.formaPagamentoSelected = formaPagamentoDeposito;
                }
                else if (this.impostoCalculado == false) {
                    this.orcamentoRequest.condicaoPagamentoSelected = this.orcamentoRequestClone.condicaoPagamentoSelected;
                    this.orcamentoRequest.formaPagamentoSelected = this.orcamentoRequestClone.formaPagamentoSelected;
                }
            },

            async aplicarEntregaTurbo(calcularTotal = true) {

                if (this.orcamentoRequest.listItemsOrcamento != null && 
                    this.orcamentoRequest.listItemsOrcamento != undefined && 
                    this.orcamentoRequest.listItemsOrcamento.length > 0) {
                    let carrinhoValido = true;

                    this.orcamentoRequest.listItemsOrcamento.forEach(itemCotacao => {
                        if (itemCotacao.importado == 1) {
                            carrinhoValido = false;
                        }
                    });                    

                    setTimeout(() => {

                        if (this.entregaTurbo == 1) {

                            if (carrinhoValido == false) {

                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Atenção',
                                    headerColor: 'orange',
                                    headerFontColor: 'white',
                                    bodyText: `Para ativar a entrega turbo é necessário que todos os itens do carrinho sejam nacionais!`,
                                    methodOK: (() => { 
                                        this.entregaTurbo = 0; 
                                    }),
                                };
                            }
                            else {

                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Atenção',
                                    headerColor: 'orange',
                                    headerFontColor: 'white',
                                    bodyText: `Ao ativar a entrega turbo, o preço dos itens aumentará seu <br />
                                            <span style="color: var(--color__red); font-weight: bold;">valor em 50%</span> e passará por aprovação da fábrica e da propria SPTech`,
                                    methodOK: (() => { }),
                                };
                            }

                        }

                        if (calcularTotal == true) {
                            this.calcularTotal();
                        }
                    }, 500);                    
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();
        }
    })
</script>

<style>
    
    input[name="campoQtde"] {
        text-align: center !important;
    }

    .btnCarrinho {
        border: none !important; 
        color: var(--color__cinza) !important;
        margin-top:5px;
    }
    
    .btnEdit {
        border: none !important; 
        margin-left: 10px;
    }

</style>